<div class="ui container section">

  <div class="username">
    <img [src]="'https://a.ppy.sh/' + user.osuUserId" height=64px width=64px>
    <h1>{{ user.username }}'s Profile{{user.banned ? ' (Banned)' :
      ''}}</h1>
  </div>
  <a class="ui purple button" target="_blank" [href]="'https://osu.ppy.sh/users/' + user.osuUserId">
    <i class="external icon"></i>
    osu! Profile
  </a>
  <div class="ui buttons" *ngIf="user.twitch">
    <a target="_blank" [href]="'https://www.twitch.tv/' + user.twitch.loginName"
      class="ui teal button">
      <i class="twitch icon"></i>
      Twitch Channel
    </a>
    <div (click)="unlinkTwitch()" *ngIf="isMyProfile" class="ui grey icon button">
      <i class="times icon"></i>
    </div>
  </div>
  <a *ngIf="!user.twitch && isMyProfile" [href]="twitchVerifyHref"
    class="ui teal button">
    <i class="twitch icon"></i>
    Connect with Twitch
  </a>

  <div class="ui right floated red button" *ngIf="isMod" (click)="banUser()">
    {{ user.banned ? 'Unban' : 'Ban'}} User
  </div>

  <h2>Stats</h2>
  <div class="ui inverted padded segment">
    <div class="ui equal width center aligned grid">
      <div class="column">
        <h5 class="ui inverted header">Games Played</h5>
        <h3>
          <i class="large green play icon"></i>
          {{ user.gamesPlayed }}
        </h3>
      </div>

      <div class="column">
        <h5 class="ui inverted header">Games Won</h5>
        <h3>
          <i class="large yellow trophy icon"></i>
          {{ user.wins }}
        </h3>
      </div>

      <div class="column">
        <h5 class="ui inverted header">Match Rating</h5>
        <h3>
          <i class="large teal crosshairs icon"></i>
          {{ user.rating.weighted.toFixed(0) }}
        </h3>
      </div>

      <div class="column">
        <h5 class="ui inverted header">Win Rate</h5>
        <h3>
          <i class="yellow percent icon"></i>
          {{
          user.wins && user.gamesPlayed
          ? ((user.wins / user.gamesPlayed) * 100).toFixed(2)
          : '0.00'
          }}
        </h3>
      </div>

      <div class="column">
        <h5 class="ui inverted header">Average Rank</h5>
        <h3 *ngIf="user.gamesPlayed && user.averageRank">
          <i class="teal arrows alternate vertical icon"></i>
          {{ user.averageRank.toFixed(2) }}
        </h3>
        <h3 *ngIf="!user.gamesPlayed">
          --
        </h3>
      </div>
    </div>
  </div>

  <br />

  <h2>Achievements</h2>
  <div class="ui inverted link list">
    <a class="item" [routerLink]="'/achievements'" id="achievements">View all achievements</a>
  </div>
  <p *ngIf="!user.achievements.length">
    No achievements gained yet.
  </p>
  <div class="ui four column doubling grid">
    <div class="column" *ngFor="let achievement of user.achievements">
      <div class="ui center aligned inverted segment achievement">
        <div>
          <i class="big icon" [ngClass]="achievement.icon"></i>
        </div>
        <h3 class="ui sub header">
          {{ achievement.title }}
        </h3>
        {{ achievement.description }}
      </div>
    </div>
  </div>

  <br />

  <h2>Match history</h2>

  <p *ngIf="!user.results.length">
    No match history data available yet.
  </p>
  <div class="ui one column doubling grid">
    <a class="column result" *ngFor="let result of user.results"
      [routerLink]="'/lobbies/' + result.gameId">
      <div class="ui inverted segment flex">
        <i class="icon" [ngClass]="getResultIconClass(result)"></i>

        <div class="result-main">
          <h3 class="ui sub header">Finished {{ result.place |
            ordinal }}</h3>
          <i class="game-date">{{
            result.gameEndedAt
            ? (result.gameEndedAt | date: 'medium')
            : 'Match still in progress...'
            }}</i>
        </div>

        <span class="elo" [ngClass]="{
            green: getRatingChange(result) >= 0,
            red: getRatingChange(result) < 0
          }">{{
          getRatingChange(result) }}</span>
      </div>
    </a>
  </div>
</div>
