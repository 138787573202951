import * as tslib_1 from "tslib";
import { SettingsService } from './../../services/settings.service';
import { ApiService } from 'src/app/services/api.service';
import { ActivatedRoute } from '@angular/router';
import { OnInit, OnDestroy } from '@angular/core';
import { interval } from 'rxjs';
import { getTimeComponents } from 'src/app/resolvers/game-lobby.resolver';
import { GameService } from '../../game.service';
var LobbiesComponent = /** @class */ (function () {
    function LobbiesComponent(route, gameService, apiService, settingsService) {
        this.route = route;
        this.gameService = gameService;
        this.apiService = apiService;
        this.settingsService = settingsService;
        this.subscriptions = [];
        this.fetching = false;
        this.canCreate = false;
        this.showGameModes = {
            '0': true,
            '1': false,
            '2': false,
            '3': false,
        };
        var enabledGameModes = settingsService.getGameModes();
        var notEnabled = Object.keys(this.showGameModes).filter(function (k) { return !enabledGameModes.includes(k); });
        for (var _i = 0, notEnabled_1 = notEnabled; _i < notEnabled_1.length; _i++) {
            var k = notEnabled_1[_i];
            this.showGameModes[k] = false;
        }
        for (var _a = 0, enabledGameModes_1 = enabledGameModes; _a < enabledGameModes_1.length; _a++) {
            var mode = enabledGameModes_1[_a];
            this.showGameModes[mode] = true;
        }
    }
    LobbiesComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.route.data.subscribe(function (_a) {
            var data = _a.data;
            _this.allLobbies = data.lobbies;
            _this.applyGameModeFilters();
            _this.onlinePlayersCount = data.onlinePlayers;
            _this.setLobbiesStartString();
        });
        this.subscriptions.push(interval(1000).subscribe(function () {
            _this.lobbies
                .filter(function (l) {
                return l.startsAt !== undefined &&
                    ['new', 'scheduled'].includes(l.status);
            })
                .forEach(function (l) {
                l.startsAt--;
            });
            _this.setLobbiesStartString();
        }));
        this.subscriptions.push(interval(30000).subscribe(function () {
            _this.fetch();
        }));
        this.subscriptions.push(this.settingsService.user.subscribe(function (user) {
            _this.canCreate =
                user &&
                    (user.roles.includes('creator') || user.roles.includes('admin'));
        }));
    };
    LobbiesComponent.prototype.ngOnDestroy = function () {
        this.subscriptions.forEach(function (sub) { return sub.unsubscribe(); });
    };
    LobbiesComponent.prototype.applyGameModeFilters = function () {
        var _this = this;
        var gameModes = (Object.keys(this.showGameModes));
        this.settingsService.setGameModes(gameModes.filter(function (k) { return !!_this.showGameModes[k]; }));
        var enabledGameModes = Object.keys(this.showGameModes)
            .map(function (key) {
            var value = _this.showGameModes[key];
            if (value) {
                return key;
            }
        })
            .filter(function (k) { return !!k; });
        this.lobbies = enabledGameModes.length
            ? this.allLobbies.filter(function (_a) {
                var gameMode = _a.gameMode;
                return [undefined].concat(enabledGameModes).includes(gameMode);
            })
            : this.allLobbies;
    };
    Object.defineProperty(LobbiesComponent.prototype, "scheduledGames", {
        get: function () {
            return this.lobbies.filter(function (l) { return l.status === 'scheduled'; });
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(LobbiesComponent.prototype, "joinableGames", {
        get: function () {
            return this.lobbies.filter(function (l) { return ['new'].includes(l.status); });
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(LobbiesComponent.prototype, "inProgressGames", {
        get: function () {
            return this.lobbies.filter(function (l) { return !['new', 'scheduled', 'complete'].includes(l.status); });
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(LobbiesComponent.prototype, "completedGames", {
        get: function () {
            return this.lobbies.filter(function (l) { return l.status === 'complete'; });
        },
        enumerable: true,
        configurable: true
    });
    LobbiesComponent.prototype.setLobbiesStartString = function () {
        this.lobbies
            .filter(function (l) { return ['new', 'scheduled'].includes(l.status); })
            .forEach(function (lobby) {
            var date = new Date();
            date.setSeconds(date.getSeconds() + lobby.startsAt);
            var _a = getTimeComponents(date.getTime() - Date.now()), days = _a.days, seconds = _a.seconds, minutes = _a.minutes, hours = _a.hours;
            if (parseInt(seconds, 10) < 0) {
                return (lobby.startsAtString = "now");
            }
            lobby.startsAtString = "" + (parseInt(days, 10) > 0 ? days + "d " : '') + (parseInt(hours, 10) > 0 ? hours + ":" : '') + minutes + ":" + seconds + "s";
        });
    };
    LobbiesComponent.prototype.fetch = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _a, _b, e_1;
            return tslib_1.__generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        if (this.fetching) {
                            return [2 /*return*/];
                        }
                        this.fetching = true;
                        _c.label = 1;
                    case 1:
                        _c.trys.push([1, 4, , 5]);
                        _a = this;
                        return [4 /*yield*/, this.gameService.getLobbies()];
                    case 2:
                        _a.allLobbies = _c.sent();
                        this.applyGameModeFilters();
                        _b = this;
                        return [4 /*yield*/, this.apiService.get('online-players')];
                    case 3:
                        _b.onlinePlayersCount = (_c.sent()).onlinePlayers;
                        return [3 /*break*/, 5];
                    case 4:
                        e_1 = _c.sent();
                        console.error(e_1);
                        return [3 /*break*/, 5];
                    case 5:
                        this.fetching = false;
                        return [2 /*return*/];
                }
            });
        });
    };
    return LobbiesComponent;
}());
export { LobbiesComponent };
