/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./page-loader.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./page-loader.component";
import * as i4 from "@angular/router";
var styles_PageLoaderComponent = [i0.styles];
var RenderType_PageLoaderComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_PageLoaderComponent, data: {} });
export { RenderType_PageLoaderComponent as RenderType_PageLoaderComponent };
function View_PageLoaderComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "ui active dimmer"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "div", [["class", "ui large text loader"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Loading..."]))], null, null); }
export function View_PageLoaderComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_PageLoaderComponent_1)), i1.ɵdid(1, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.visible; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_PageLoaderComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-page-loader", [], null, null, null, View_PageLoaderComponent_0, RenderType_PageLoaderComponent)), i1.ɵdid(1, 245760, null, 0, i3.PageLoaderComponent, [i4.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var PageLoaderComponentNgFactory = i1.ɵccf("app-page-loader", i3.PageLoaderComponent, View_PageLoaderComponent_Host_0, {}, {}, []);
export { PageLoaderComponentNgFactory as PageLoaderComponentNgFactory };
