import * as tslib_1 from "tslib";
import { ApiService } from './../../services/api.service';
import { ActivatedRoute } from '@angular/router';
import { OnInit, OnDestroy } from '@angular/core';
import { SettingsService } from '../../services/settings.service';
import { environment } from '../../../environments/environment';
var UserProfileComponent = /** @class */ (function () {
    function UserProfileComponent(route, settingsService, apiService) {
        var _this = this;
        this.apiService = apiService;
        this.subscriptions = [];
        this.subscriptions = [
            route.data.subscribe(function (_a) {
                var data = _a.data;
                _this.user = data.user;
            }),
            settingsService.user.subscribe(function (u) { return (_this.currentUser = u); }),
        ];
    }
    UserProfileComponent.prototype.ngOnInit = function () { };
    UserProfileComponent.prototype.ngOnDestroy = function () {
        this.subscriptions.forEach(function (s) { return s.unsubscribe(); });
    };
    UserProfileComponent.prototype.getRatingChange = function (result) {
        if (!result.ratingAfter || !result.ratingBefore) {
            return '- -';
        }
        if (result.ratingAfter.weighted && result.ratingBefore.weighted) {
            var value = parseFloat((result.ratingAfter.weighted - result.ratingBefore.weighted).toFixed(1));
            return value >= 0 ? "+" + value : value;
        }
    };
    UserProfileComponent.prototype.getResultIconClass = function (result) {
        if (result.place === 1) {
            return 'yellow trophy';
        }
        if (result.gameEndedAt) {
            return 'grey flag checkered';
        }
        return 'orange eye';
    };
    UserProfileComponent.prototype.banUser = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var e_1;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.apiService.post("admin/ban-user/", {
                                osuUserId: this.user.osuUserId,
                            })];
                    case 1:
                        _a.sent();
                        window.location.reload();
                        return [3 /*break*/, 3];
                    case 2:
                        e_1 = _a.sent();
                        console.error(e_1);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    UserProfileComponent.prototype.unlinkTwitch = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.apiService.post('user/unlink-twitch', {})];
                    case 1:
                        _a.sent();
                        window.location.reload();
                        return [2 /*return*/];
                }
            });
        });
    };
    Object.defineProperty(UserProfileComponent.prototype, "isMod", {
        get: function () {
            return this.currentUser && this.currentUser.roles && this.currentUser.roles.includes('moderator');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(UserProfileComponent.prototype, "isMyProfile", {
        get: function () {
            return (this.currentUser && this.user.osuUserId === this.currentUser.osuUserId);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(UserProfileComponent.prototype, "twitchVerifyHref", {
        get: function () {
            return ('https://id.twitch.tv/oauth2/authorize' +
                ("?client_id=" + environment.twitch_client_id) +
                ("&redirect_uri=" + environment.twitch_redirect_url) +
                '&response_type=code' +
                '&force_verify=true');
        },
        enumerable: true,
        configurable: true
    });
    return UserProfileComponent;
}());
export { UserProfileComponent };
