<div class="ui inverted padded segment">
  <div class="ui stackable grid">
    <div class="twelve wide column">
      <h3>{{ title }}</h3>
      <p>{{ message }}</p>
    </div>
    <div class="four wide right aligned column">
      <div *ngIf="!viewingRoundResults && game.status !== 'new' && game.roundNumber > 1"
        class="tiny ui basic inverted button" (click)="toggleShowResults()">
        View past round results
      </div>
    </div>
  </div>

  <div class="rank-restrictions" *ngIf="showAdditionalDescription && (game.minRank || game.maxRank)">
    <div class="ui grid">
      <div class="column">
        <h3>This game has rank restrictions</h3>
        <p *ngIf="game.minRank">- Only players above rank
          {{game.minRank}} can join</p>
        <p *ngIf="game.maxRank">- Only players below rank
          {{game.maxRank}} can join</p>
      </div>
    </div>
  </div>
</div>

<div class="ui inverted center aligned padded red segment" *ngIf="mePlayer && !isAlive && game.status !== 'new'">
  <div class="ui icon header">
    <i class="crosshairs icon"></i>
    You have been eliminated from the match.
  </div>
  <p class="less-mr">You finished {{ rank | ordinal }} out of {{
    totalPlayers }} players</p>
  <p>You may continue watching or join another lobby!</p>
</div>

<div class="ui inverted center aligned padded green segment"
  *ngIf="isWinner">
  <div class="ui icon header">
    <i class="yellow trophy icon"></i>
    Congratulations!
    <br>
    You won the match!
  </div>
</div>
