<div class="ui inverted segment no-bg">
  <div class="ui stackable grid">
    <div class="ten wide column">
      <h3>Match Results</h3>
    </div>
    <div class="six wide right aligned column">
      <div class="ui primary button" (click)="hideResults()" *ngIf="game.status !== 'complete'">
        <i class="left angle icon"></i>
        View live game
      </div>
    </div>
  </div>
  <div class="ui inverted secondary menu">
    <a class="item" [class.active]="round && round.roundNumber === roundNum" (click)="showRound(roundNum)" *ngFor="let roundNum of rounds">
      Round {{roundNum}} {{ finalRound === roundNum ? '- Final' : '' }}
    </a>
  </div>
  <div *ngIf="round">
    <h3>
      <app-beatmap-info [game]="game" [hideTitle]="true" [beatmap]="round.beatmap"></app-beatmap-info>
    </h3>
    <div *ngIf="!round.scores || !round.scores.length">
      <p>
        This round has no scores submitted
      </p>
    </div>
    <div *ngIf="round.scores && round.scores.length">
      <h3>Round {{round.roundNumber}} scores</h3>
      <app-scores-table *ngIf="round" [scores]="round.scores" [game]="game"></app-scores-table>
    </div>
  </div>
</div>
