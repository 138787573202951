import * as tslib_1 from "tslib";
import { ApiService } from './../services/api.service';
import { GameService } from '../game.service';
import { SettingsService } from '../services/settings.service';
import * as i0 from "@angular/core";
import * as i1 from "../game.service";
import * as i2 from "../services/settings.service";
import * as i3 from "../services/api.service";
var LobbiesResolver = /** @class */ (function () {
    function LobbiesResolver(gameService, settingsService, apiService) {
        this.gameService = gameService;
        this.settingsService = settingsService;
        this.apiService = apiService;
    }
    LobbiesResolver.prototype.resolve = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var onlinePlayers, _a;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0: return [4 /*yield*/, this.settingsService.checkCurrentGame()];
                    case 1:
                        _b.sent();
                        return [4 /*yield*/, this.apiService.get('online-players')];
                    case 2:
                        onlinePlayers = (_b.sent()).onlinePlayers;
                        _a = {};
                        return [4 /*yield*/, this.gameService.getLobbies()];
                    case 3: return [2 /*return*/, (_a.lobbies = _b.sent(),
                            _a.onlinePlayers = onlinePlayers,
                            _a)];
                }
            });
        });
    };
    LobbiesResolver.ngInjectableDef = i0.defineInjectable({ factory: function LobbiesResolver_Factory() { return new LobbiesResolver(i0.inject(i1.GameService), i0.inject(i2.SettingsService), i0.inject(i3.ApiService)); }, token: LobbiesResolver, providedIn: "root" });
    return LobbiesResolver;
}());
export { LobbiesResolver };
