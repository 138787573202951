import * as tslib_1 from "tslib";
import { WebsocketService } from './../../../services/websocket.service';
import { GameService } from './../../../game.service';
import { OnInit, AfterViewInit, } from '@angular/core';
var ChatComponent = /** @class */ (function () {
    function ChatComponent(gameService, socketService) {
        this.gameService = gameService;
        this.socketService = socketService;
        this.sortedMessages = [];
        this.sendingMessage = false;
        this.showEmojiPicker = false;
        this.stopAutoScroll = false;
        this.detectScroll = false;
    }
    Object.defineProperty(ChatComponent.prototype, "messages", {
        set: function (msgs) {
            this.setMessages(msgs);
        },
        enumerable: true,
        configurable: true
    });
    ChatComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.socketService.socket.on('chat-message', function (data) {
            _this.newMessage(data);
        });
    };
    ChatComponent.prototype.ngAfterViewInit = function () {
        var _this = this;
        setTimeout(function () {
            _this.detectScroll = true;
        }, 1000);
    };
    ChatComponent.prototype.newMessage = function (msg) {
        this.sortedMessages.push(msg);
        this.scrollToBottom();
    };
    ChatComponent.prototype.setMessages = function (msgs) {
        var lengthChanged = msgs.length !== this.sortedMessages.length;
        if (!this.sortedMessages || lengthChanged) {
            this.sortedMessages = msgs.slice().reverse();
            this.scrollToBottom();
        }
    };
    ChatComponent.prototype.scrollToBottom = function () {
        var _this = this;
        if (this.stopAutoScroll) {
            return;
        }
        setTimeout(function () {
            _this.chatMessages.nativeElement.scrollTop = _this.chatMessages.nativeElement.scrollHeight;
        }, 100);
    };
    ChatComponent.prototype.onChatScrolled = function () {
        var _this = this;
        if (!this.detectScroll) {
            return;
        }
        this.stopAutoScroll = true;
        setTimeout(function () {
            _this.stopAutoScroll = false;
        }, 500);
    };
    ChatComponent.prototype.onMouseDown = function () {
        this.stopAutoScroll = true;
    };
    ChatComponent.prototype.onMouseUp = function () {
        this.stopAutoScroll = false;
    };
    ChatComponent.prototype.sendMessage = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                if (!this.messageInput) {
                    return [2 /*return*/];
                }
                this.sendingMessage = true;
                this.socketService.socket.emit('send-message', {
                    message: this.messageInput,
                    gameId: this.game._id,
                });
                try {
                    this.messageInput = undefined;
                }
                catch (e) {
                    console.error(e);
                }
                this.sendingMessage = false;
                this.focusChatInput();
                return [2 /*return*/];
            });
        });
    };
    ChatComponent.prototype.focusChatInput = function () {
        var _this = this;
        setTimeout(function () {
            _this.chatInputEl.nativeElement.focus();
        }, 100);
    };
    ChatComponent.prototype.addEmoji = function (e) {
        this.messageInput = (this.messageInput || '') + e.emoji.native;
        this.showEmojiPicker = false;
        this.focusChatInput();
    };
    ChatComponent.prototype.windowClick = function (e) {
        if (e.target.classList.contains('show-emoji-picker')) {
            this.showEmojiPicker = !this.showEmojiPicker;
        }
        else if (!e.path || !e.path.some(function (el) { return el.tagName === 'EMOJI-MART'; })) {
            this.showEmojiPicker = false;
        }
    };
    ChatComponent.prototype.isSystem = function (message) {
        return (message.osuUserId === 0 && message.username.toLowerCase() === 'system');
    };
    return ChatComponent;
}());
export { ChatComponent };
