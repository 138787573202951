import * as tslib_1 from "tslib";
import { SettingsService } from 'src/app/services/settings.service';
import { interval } from 'rxjs';
import { ApiService } from './../services/api.service';
import { OnInit } from '@angular/core';
import { TransitionController, Transition, TransitionDirection } from 'ng2-semantic-ui';
import { startWith } from 'rxjs/operators';
var NotificationsComponent = /** @class */ (function () {
    function NotificationsComponent(apiService, settingsService) {
        this.apiService = apiService;
        this.settingsService = settingsService;
        this.transitionController = new TransitionController(false);
    }
    NotificationsComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.settingsService.user.subscribe(function (val) {
            if (val && !_this.subscription) {
                _this.subscription = interval(15000)
                    .pipe(startWith(0))
                    .subscribe(function () { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                    var achievement, e_1;
                    return tslib_1.__generator(this, function (_a) {
                        switch (_a.label) {
                            case 0:
                                _a.trys.push([0, 2, , 3]);
                                return [4 /*yield*/, this.apiService.get('unread-achievements')];
                            case 1:
                                achievement = (_a.sent());
                                if (achievement) {
                                    this.achievement = achievement;
                                    this.showNotification();
                                }
                                else {
                                    // this.dismissAchievement();
                                }
                                return [3 /*break*/, 3];
                            case 2:
                                e_1 = _a.sent();
                                console.error(e_1);
                                return [3 /*break*/, 3];
                            case 3: return [2 /*return*/];
                        }
                    });
                }); });
            }
            else if (!val && _this.subscription) {
                _this.subscription.unsubscribe();
                _this.subscription = undefined;
            }
        });
    };
    NotificationsComponent.prototype.showNotification = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var audio;
            return tslib_1.__generator(this, function (_a) {
                try {
                    audio = new Audio();
                    audio.src = '/assets/sounds/success.wav';
                    audio.volume = 0.2;
                    audio.load();
                    audio.play();
                }
                catch (e) {
                    console.error(e);
                }
                this.animate(TransitionDirection.In);
                return [2 /*return*/];
            });
        });
    };
    NotificationsComponent.prototype.animate = function (direction) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, new Promise(function (res) {
                        var name = direction === TransitionDirection.Out ? 'fly left' : 'fly left';
                        _this.transitionController.animate(new Transition(name, 250, direction, function () {
                            res();
                        }));
                    })];
            });
        });
    };
    NotificationsComponent.prototype.dismissAchievement = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.animate(TransitionDirection.Out)];
                    case 1:
                        _a.sent();
                        this.achievement = undefined;
                        return [2 /*return*/];
                }
            });
        });
    };
    return NotificationsComponent;
}());
export { NotificationsComponent };
