import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { ApiService } from '../../services/api.service';
export var gameModeOpts = [
    { value: '0', label: 'osu!Standard' },
    { value: '1', label: 'Taiko' },
    { value: '2', label: 'CtB' },
    { value: '3', label: 'Mania' },
];
var LobbyFormComponent = /** @class */ (function () {
    function LobbyFormComponent(apiService) {
        var _this = this;
        this.apiService = apiService;
        this.pickerMinDate = new Date();
        this.gameModes = gameModeOpts;
        this.formData = {
            title: '',
            description: '',
            timezoneOffset: -Math.round(new Date().getTimezoneOffset() / 60),
            minPlayers: 4,
            maxPlayers: 250,
            setMinRank: false,
            setMaxRank: false,
            minRank: 50,
            maxRank: 1000000,
            roundBeatmaps: new Array(10).fill(null).map(function () { return ({
                beatmapId: undefined,
                beatmap: undefined,
            }); }),
            gameMode: gameModeOpts[0],
            password: '',
        };
        this.creating = false;
        this.getBeatmap = debounce(function (value, index) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            var roundBeatmap, mode, beatmap, e_1;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        roundBeatmap = this.formData.roundBeatmaps[index];
                        roundBeatmap.fetching = true;
                        roundBeatmap.error = undefined;
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        mode = this.formData.gameMode.value;
                        return [4 /*yield*/, this.apiService.get("beatmap/" + value + "?m=" + mode)];
                    case 2:
                        beatmap = (_a.sent()).beatmap;
                        if (!beatmap) {
                            roundBeatmap.error = 'Beatmap not found with this ID. Have you selected the right game mode?';
                            roundBeatmap.fetching = false;
                            return [2 /*return*/];
                        }
                        roundBeatmap.beatmap = beatmap;
                        return [3 /*break*/, 4];
                    case 3:
                        e_1 = _a.sent();
                        console.error(e_1);
                        roundBeatmap.error = 'Unable to retrieve the beatmap';
                        return [3 /*break*/, 4];
                    case 4:
                        roundBeatmap.fetching = false;
                        return [2 /*return*/];
                }
            });
        }); }, 250);
        this.gameMode = this.gameModes.find(function (m) { return m.value === _this.formData.gameMode.value; });
    }
    LobbyFormComponent.prototype.ngOnInit = function () { };
    LobbyFormComponent.prototype.onGameModeChange = function ($event) {
        this.formData.gameMode = $event;
    };
    LobbyFormComponent.prototype.getBeatmapString = function (beatmap) {
        return beatmap.artist + " - " + beatmap.title + " [" + beatmap.version + "] (" + parseFloat(beatmap.difficultyrating).toFixed(2) + "*)";
    };
    LobbyFormComponent.prototype.clearBeatmap = function (index) {
        var round = this.formData.roundBeatmaps[index];
        if (round) {
            round.beatmap = undefined;
            round.fetching = false;
            round.beatmapId = undefined;
            round.error = undefined;
        }
    };
    return LobbyFormComponent;
}());
export { LobbyFormComponent };
function debounce(func, wait, immediate) {
    var timeout;
    return function () {
        var context = this, args = arguments;
        var later = function () {
            timeout = null;
            if (!immediate) {
                func.apply(context, args);
            }
        };
        var callNow = immediate && !timeout;
        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
        if (callNow) {
            func.apply(context, args);
        }
    };
}
