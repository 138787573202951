import * as tslib_1 from "tslib";
import { GameService } from './../game.service';
import * as i0 from "@angular/core";
import * as i1 from "../game.service";
var EditLobbyResolver = /** @class */ (function () {
    function EditLobbyResolver(gameService) {
        this.gameService = gameService;
    }
    EditLobbyResolver.prototype.resolve = function (route) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var id, _a;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        id = route.params.id;
                        _a = {};
                        return [4 /*yield*/, this.gameService.getLobby(id)];
                    case 1:
                        _a.lobby = _b.sent();
                        return [4 /*yield*/, this.gameService.getLobbyBeatmaps(id)];
                    case 2: return [2 /*return*/, (_a.beatmaps = _b.sent(),
                            _a)];
                }
            });
        });
    };
    EditLobbyResolver.ngInjectableDef = i0.defineInjectable({ factory: function EditLobbyResolver_Factory() { return new EditLobbyResolver(i0.inject(i1.GameService)); }, token: EditLobbyResolver, providedIn: "root" });
    return EditLobbyResolver;
}());
export { EditLobbyResolver };
