<div class="ui basic inverted segment no-bg">
  <h3 class="ui inverted header" *ngIf="!hideTitle">
    {{ game.status === 'in-progress' ? 'Now Playing' :
    'Up next' }}
  </h3>


  <div [ngStyle]="getBgStyles()" class="beatmap-bg" *ngIf="beatmap">
    <div class="ui padded inverted segment">
      <div class="ui inverted relaxed list">
        <div class="item">
          <div class="ui stackable three column grid">
            <div class="column">
              <a [href]="beatmapHref" target="_blank" class="beatmap-link">
                <h3>
                  <i class="download icon"></i> {{
                  beatmap.artist }} - {{ beatmap.title }}
                </h3>
                <h2 class="beatmap-version">
                  <i class="small bullseye icon"></i> [{{
                  beatmap.version }}]
                </h2>
              </a>
            </div>
            <div class="center aligned column">
              <a class="ui primary tiny button" target="_blank" [href]="dlLink">
                <i class="download icon"></i>
                Download
              </a>
              <a class="ui primary tiny button" [href]="osuDirectLink">
                <i class="download icon"></i>
                osu!direct
              </a>
            </div>
            <div class="right aligned column">
              <p>
                Star Difficulty: <i class="yellow star outline icon"></i>
                {{ starRating }}
              </p>
              <p>
                Map Length: <i class=" stopwatch icon"></i> {{
                duration }}
              </p>
            </div>
            <div class="sixteen wide center aligned column" *ngIf="game.status === 'in-progress' && isAlive">
              <h3 class="ui icon header">
                <i class="bullhorn icon"></i>
                The round has started. Play this map now!
              </h3>
              <h1 class="ui header">
                {{ timeLeft }}
              </h1>
              <br>
              <p><i class="yellow crosshairs icon"></i> Play in
                single player mode</p>
              <p><i class="yellow crosshairs icon"></i> You can
                pick any rankable mods (except Score V2)</p>
              <p><i class="yellow crosshairs icon"></i> Play
                this difficulty: [{{ beatmap.version }}]</p>
              <p><i class="yellow crosshairs icon"></i> Only
                your highest score will count</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

</div>
