<div class="wrapper" *ngIf="game">
  <div class="header">
    <app-game-info [game]="game" [timeLeft]="timeLeft" [inGame]="inGame" [isOwner]="isOwner"></app-game-info>
  </div>
  <div class="main">
    <div class="left">
      <div class="lobby-details" [suiTransition]="transitionController">
        <app-join-game [game]="game" [inAnotherGame]="inAnotherGame" *ngIf="showJoinGame"></app-join-game>
        <app-game-status [viewingRoundResults]="game.status === 'complete' || viewResults" [toggleViewResults]="toggleViewResults"
          [timeLeft]="timeLeft" [game]="game" [isAlive]="isAlive" [mePlayer]="mePlayer"
          [totalPlayers]="players.length">
        </app-game-status>
        <app-game-description *ngIf="showDescription" [game]="game"></app-game-description>
        <app-match-results [toggleViewResults]="toggleViewResults" *ngIf="game.status === 'complete' || viewResults"
          [game]="game">
        </app-match-results>
        <app-beatmap-list [game]="game" [beatmaps]="beatmaps" *ngIf="showBeatmaps"></app-beatmap-list>
        <app-beatmap-info *ngIf="showBeatmap" [timeLeft]="timeLeft" [game]="game" [beatmap]="currentBeatmap" [isAlive]="isAlive"></app-beatmap-info>
        <app-scores-list *ngIf="showScores" [inGame]="inGame" [currentUser]="currentUsername" [players]="players" [game]="game" [scores]="game.scores"></app-scores-list>
      </div>
      <app-chat [game]="game" [messages]="messages" [canChat]="isModOrAdmin || mePlayer" [currentUsername]="currentUsername">
      </app-chat>
    </div>
    <div class="right">
      <app-user-list [game]="game" [currentUser]="currentUser" [players]="players" [winningUser]="game.winningUser && game.winningUser.username"></app-user-list>
    </div>
  </div>

</div>
