import * as tslib_1 from "tslib";
import { AdminService } from './services/admin.service';
import { SettingsService } from 'src/app/services/settings.service';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { ApiService } from './services/api.service';
var AppComponent = /** @class */ (function () {
    function AppComponent(router, settingsService, adminService, apiService) {
        var _this = this;
        this.adminService = adminService;
        this.apiService = apiService;
        this.title = 'osu Royal Tournament';
        this.connectionLost = false;
        router.events
            .pipe(filter(function (event) { return event instanceof NavigationEnd; }))
            .subscribe(function (event) {
            _this.showMenu = event.url === '/lobbies/create' || !event.url.startsWith('/lobbies/');
            _this.url = event.url;
            if (event.url.startsWith('/lobbies/')) {
                var idx = event.url.indexOf('?');
                var endIdx = idx !== -1 ? event.url.indexOf('?') - '/lobbies/'.length : undefined;
                _this.gameId = event.url.substr('/lobbies/'.length, endIdx);
            }
            else {
                _this.gameId = undefined;
            }
        });
        this.isAdmin = !!settingsService.adminPw;
        settingsService.currentGame.subscribe(function (val) { return (_this.currentGame = val); });
        settingsService.user.subscribe(function (val) { return (_this.currentUser = val); });
        this.apiService.connectionLost.subscribe(function (val) { return _this.connectionLost = val; });
    }
    Object.defineProperty(AppComponent.prototype, "currentUsername", {
        get: function () {
            return this.currentUser && this.currentUser.username;
        },
        enumerable: true,
        configurable: true
    });
    AppComponent.prototype.skipRound = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.adminService.skipRound(this.gameId)];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    AppComponent.prototype.toggleFreeze = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.adminService.toggleFreeze()];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    AppComponent.prototype.clearDb = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.adminService.clearDb()];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    AppComponent.prototype.toggleAutoCreation = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.adminService.toggleAutoCreate()];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    AppComponent.prototype.deleteLobby = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.adminService.deleteLobby(this.gameId)];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    AppComponent.prototype.createTestUser = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.adminService.createTestUser()];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    return AppComponent;
}());
export { AppComponent };
