import * as tslib_1 from "tslib";
import { ApiService } from './services/api.service';
import * as i0 from "@angular/core";
import * as i1 from "./services/api.service";
var GameService = /** @class */ (function () {
    function GameService(apiService) {
        this.apiService = apiService;
    }
    GameService.prototype.getLobbies = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.apiService.get('lobbies')];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    GameService.prototype.getLobby = function (id) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.apiService.get("lobbies/" + id)];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    GameService.prototype.getLobbyBeatmaps = function (id) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.apiService.get("lobbies/" + id + "/beatmaps")];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    GameService.prototype.getLobbyUsers = function (id) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.apiService.get("lobbies/" + id + "/users")];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    GameService.prototype.getLobbyRound = function (id, roundNum) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.apiService.get("lobbies/" + id + "/rounds/" + roundNum)];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    GameService.prototype.getLobbyMessages = function (id, afterId) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.apiService.get("lobbies/" + id + "/messages", {
                            query: afterId ? { afterId: afterId } : undefined,
                        })];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    GameService.prototype.kickUser = function (gameId, playerId) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.apiService.post("lobbies/" + gameId + "/players/" + playerId + "/kick", {})];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    GameService.prototype.createScheduledGame = function (options) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.apiService.post("lobbies/schedule-game", options)];
                    case 1: return [2 /*return*/, (_a.sent())];
                }
            });
        });
    };
    GameService.prototype.editGame = function (gameId, options) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.apiService.put("lobbies/" + gameId, options)];
                    case 1: return [2 /*return*/, (_a.sent())];
                }
            });
        });
    };
    GameService.prototype.deleteLobby = function (gameId) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.apiService.post("admin/delete-lobby", { gameId: gameId })];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    GameService.ngInjectableDef = i0.defineInjectable({ factory: function GameService_Factory() { return new GameService(i0.inject(i1.ApiService)); }, token: GameService, providedIn: "root" });
    return GameService;
}());
export { GameService };
