import { OnInit } from '@angular/core';
var UserPopupComponent = /** @class */ (function () {
    function UserPopupComponent() {
    }
    UserPopupComponent.prototype.ngOnInit = function () { };
    Object.defineProperty(UserPopupComponent.prototype, "popupPosition", {
        get: function () {
            return {
                top: this.popupData.offsetTop + "px",
            };
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(UserPopupComponent.prototype, "player", {
        get: function () {
            return this.popupData.player;
        },
        enumerable: true,
        configurable: true
    });
    return UserPopupComponent;
}());
export { UserPopupComponent };
