<div class="ui inverted basic segment">
  <h1 class="ui inverted header">Leaderboard</h1>
    <p><i>Leaderboards may be reset when adjustments to the rating algorithm are made.</i></p>
  <table class="ui inverted unstackable table">
    <thead class="blue">
      <th class="center aligned">Rank</th>
      <th>Rating</th>
      <th>Username</th>
      <th>Global Rank</th>
      <th>Country Rank</th>
      <th class="center aligned">Wins</th>
      <th class="center aligned">Games Played</th>
      <th class="center aligned">Win Rate</th>
      <th class="center aligned">Top 10%</th>
      <th class="center aligned">Top 20%</th>
      <th class="center aligned">Top 50%</th>
    </thead>
    <tbody>
      <tr *ngFor="let user of users; let index = index">
        <td class="collapsing">
          # {{ index + 1 }}
        </td>
        <td class="collapsing">
          <i class="orange exclamation link icon" *ngIf="user.gamesPlayed < 5" popupPlacement="right" suiPopup popupText="User has less than 5 games played"></i>
          <i class="teal crosshairs icon" *ngIf="user.gamesPlayed >= 5"></i>
          {{ user.gamesPlayed > 0 && user.rating.weighted ? user.rating.weighted.toFixed(0) : '—' }}
        </td>
        <td class="collapsing">
          <a [routerLink]="['/user', user.username]">
            {{ user.username }}
          </a>
        </td>
        <td class="collapsing">
          <i class="fitted blue globe icon"></i>
          {{ user.ppRank ? ('#' + (user.ppRank | number)) : '—' }}
        </td>
        <td class="collapsing">
          <i class="fitted flag" [ngClass]="user.country.toLowerCase()"></i>
          {{ user.countryRank | number }}
        </td>
        <td class="center aligned collapsing">
          <i class="trophy icon" [ngClass]="getWinsColor(user)"></i>
          {{ user.wins }}
        </td>
        <td class="center aligned">
          {{ user.gamesPlayed }}
        </td>
        <td class="center aligned">
          <span *ngIf="user.gamesPlayed">
            {{ getPercent(user.wins, user) }}
          </span>
          <span *ngIf="!user.gamesPlayed">
            —
          </span>
        </td>
        <td class="center aligned">
          {{ user.gamesPlayed ?
          getPercent(user.percentiles.top10, user) : '—' }}
        </td>
        <td class="center aligned">
          {{ user.gamesPlayed ?
          getPercent(user.percentiles.top20, user) : '—' }}
        </td>
        <td class="center aligned">
          {{ user.gamesPlayed ?
          getPercent(user.percentiles.top50, user) : '—' }}
        </td>
      </tr>
    </tbody>
  </table>
</div>
