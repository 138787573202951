import * as tslib_1 from "tslib";
import { GameService } from './../../game.service';
import { OnInit } from '@angular/core';
import { Router } from '@angular/router';
var CreateLobbyComponent = /** @class */ (function () {
    function CreateLobbyComponent(gameService, router) {
        this.gameService = gameService;
        this.router = router;
        this.creating = false;
        this.createGame = this.doCreateGame.bind(this);
    }
    CreateLobbyComponent.prototype.ngOnInit = function () { };
    CreateLobbyComponent.prototype.doCreateGame = function (formData) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var date, options, gameId, e_1;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.error = undefined;
                        this.creating = true;
                        if (!formData) {
                            this.error = 'A start date must be set';
                            this.creating = false;
                            return [2 /*return*/];
                        }
                        date = formData.startDate;
                        if (formData.timezoneOffset) {
                            date.setHours(date.getHours() + formData.timezoneOffset);
                        }
                        options = {
                            nextStageStarts: formData.startDate,
                            title: formData.title,
                            description: formData.description,
                            minPlayers: formData.minPlayers,
                            maxPlayers: formData.maxPlayers,
                            roundBeatmaps: formData.roundBeatmaps.map(function (r) { return r.beatmap; }),
                            minRank: formData.setMinRank ? formData.minRank : undefined,
                            maxRank: formData.setMaxRank ? formData.maxRank : undefined,
                            gameMode: formData.gameMode.value,
                            password: formData.password,
                        };
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, this.gameService.createScheduledGame(options)];
                    case 2:
                        gameId = (_a.sent()).gameId;
                        this.router.navigate(['/lobbies', gameId]);
                        return [3 /*break*/, 4];
                    case 3:
                        e_1 = _a.sent();
                        this.creating = false;
                        if (e_1.error && e_1.error.error) {
                            this.error = e_1.error.error;
                        }
                        else {
                            if (e_1.status === 401) {
                                this.error = 'You do not have the permissions to create a lobby';
                            }
                            else {
                                this.error = 'Failed to create game';
                                console.error(e_1);
                            }
                        }
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    return CreateLobbyComponent;
}());
export { CreateLobbyComponent };
