<div class="ui inverted segment footer">
  <div class="ui container">
    <div class="ui stackable inverted divided equal height stackable grid">
      <div class="three wide column">
        <h4 class="ui inverted header">Site map</h4>
        <div class="ui inverted link list">
          <a [routerLink]="'/'" class="item">Home</a>
          <a [routerLink]="'/lobbies'" class="item">Play</a>
          <a [routerLink]="'/leaderboard'" class="item">Leaderboard</a>
          <a [routerLink]="'/achievements'" class="item">Achievements</a>
          <a [routerLink]="'/faq'" class="item">FAQ</a>
          <a href="https://github.com/Hughp135/osustuff-tournaments" class="item">GitHub</a>
        </div>
      </div>
      <div class="three wide column">
        <h4 class="ui inverted header">osuStuff links</h4>
        <div class="ui inverted link list">
          <a href="https://www.osustuff.org/avatar-maker" target="_blank" class="item">Avatar Maker
          </a>
          <a href="https://www.osustuff.org/bounties/" target="_blank" class="item">Bounties
          </a>
          <a href="https://discord.gg/wFxEaE9" target="_blank" class="item">
            Discord Server
          </a>
        </div>
      </div>
      <div class="seven wide column">
        <h4 class="ui inverted header">About</h4>
        <p>
          A website made by osu players for osu players since
          2015.
          This website is not officially affiliated with the
          game <a target="_blank" href="https://osu.ppy.sh">osu!</a>®
        </p>
        <p>
          Copyright 2019. All rights reserved.
        </p>
      </div>
    </div>
  </div>
</div>
