import * as tslib_1 from "tslib";
import * as io from 'socket.io-client';
import { environment } from '../../environments/environment';
import { BehaviorSubject } from 'rxjs';
import * as i0 from "@angular/core";
var WebsocketService = /** @class */ (function () {
    function WebsocketService() {
        this.connected = false;
        this.reconnecting = false;
        this.lobby = new BehaviorSubject(undefined);
        this.players = new BehaviorSubject(undefined);
    }
    WebsocketService.prototype.connect = function (gameId) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (this.socket && this.socket.connected) {
                            return [2 /*return*/];
                        }
                        this.socket = io.connect(environment.socket_url);
                        return [4 /*yield*/, this.addSocketListeners(gameId)];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    WebsocketService.prototype.addSocketListeners = function (gameId) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, new Promise(function (res, rej) {
                        _this.socket.on('connect', function (data) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                            return tslib_1.__generator(this, function (_a) {
                                if (this.reconnecting) {
                                }
                                this.socket.emit('join-game', gameId);
                                this.connected = true;
                                this.reconnecting = false;
                                res();
                                return [2 /*return*/];
                            });
                        }); });
                        _this.socket.on('disconnect', function (reason) {
                            console.warn('disconnected', reason);
                            // SOCKET CONNECTION LOST
                            _this.connected = false;
                            _this.reconnecting = true;
                            rej(reason);
                        });
                        _this.socket.on('error', function (data) {
                            /* istanbul ignore next  */
                            if (data === 'No token provided' ||
                                data === 'Invalid token' ||
                                data === 'User not found') {
                                rej('unauthenticated');
                            }
                            console.error(data);
                        });
                        _this.socket.on('game-updated', function (game) {
                            _this.lobby.next(game);
                        });
                        _this.socket.on('players-updated', function (_a) {
                            var players = _a.players, id = _a.gameId;
                            _this.players.next({ players: JSON.parse(players), gameId: gameId });
                        });
                    })];
            });
        });
    };
    WebsocketService.ngInjectableDef = i0.defineInjectable({ factory: function WebsocketService_Factory() { return new WebsocketService(); }, token: WebsocketService, providedIn: "root" });
    return WebsocketService;
}());
export { WebsocketService };
