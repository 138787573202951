<div class="ui padded inverted segment notification-container" [suiTransition]="transitionController">
  <h4 class="ui center aligned header">Achievement Unlocked!</h4>
  <i class="times link icon close" (click)="dismissAchievement()"></i>
  <div class="center aligned ui inverted segment achievement-container" *ngIf="achievement" (click)="dismissAchievement()">
    <div>
      <i class="big icon" [ngClass]="achievement.icon"></i>
    </div>
    <h3 class="ui sub header">
      {{ achievement.title}}
    </h3>
    {{ achievement.description }}
  </div>
</div>
