/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./faq.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./faq.component";
var styles_FaqComponent = [i0.styles];
var RenderType_FaqComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_FaqComponent, data: {} });
export { RenderType_FaqComponent as RenderType_FaqComponent };
export function View_FaqComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 120, "div", [["class", "ui container section"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "h1", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Frequently Asked Questions"])), (_l()(), i1.ɵeld(3, 0, null, null, 117, "div", [["class", "ui inverted segment"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 116, "div", [["class", "ui inverted accordion"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 2, "div", [["class", "title"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = ((_co.activeQuestion = 1) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 0, "i", [["class", "dropdown icon"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" How are scores automatically detected? "])), (_l()(), i1.ɵeld(8, 0, null, null, 4, "div", [["class", "content"]], [[2, "active", null]], null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 3, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" At the end of each round, the system checks your recent scores from the osu! API. It finds any scores you have set on the beatmap and takes the one with the highest score, and uses that to determine if you pass the round. "])), (_l()(), i1.ɵeld(11, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Only scores set after the round starts will count. "])), (_l()(), i1.ɵeld(13, 0, null, null, 2, "div", [["class", "title"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = ((_co.activeQuestion = 2) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(14, 0, null, null, 0, "i", [["class", "dropdown icon"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" How are beatmaps chosen? "])), (_l()(), i1.ɵeld(16, 0, null, null, 4, "div", [["class", "content"]], [[2, "active", null]], null, null, null, null)), (_l()(), i1.ɵeld(17, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" The system will automatically select 10 beatmaps for every match from recently ranked/loved maps. It picks a mix of new maps (ranked in the last month) and old maps (a year is randomly picked from the last 5 years to choose older maps from). There should be a roughly 50/50 mix of new and older maps. "])), (_l()(), i1.ɵeld(19, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Support for custom games with your own choice of beatmaps will be added in the future. "])), (_l()(), i1.ɵeld(21, 0, null, null, 2, "div", [["class", "title"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = ((_co.activeQuestion = 3) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(22, 0, null, null, 0, "i", [["class", "dropdown icon"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Are there any plans to add additional tournament formats? "])), (_l()(), i1.ɵeld(24, 0, null, null, 7, "div", [["class", "content"]], [[2, "active", null]], null, null, null, null)), (_l()(), i1.ɵeld(25, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Currently the only tournament format is the 'Royale' format - e.g. elimination down to 1 winner. "])), (_l()(), i1.ɵeld(27, 0, null, null, 4, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" More game modes will most likely be added in future as per popular demands, and as contributors on "])), (_l()(), i1.ɵeld(29, 0, null, null, 1, "a", [["href", "https://github.com/Hughp135/osustuff-tournaments"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["our GitHub"])), (_l()(), i1.ɵted(-1, null, [" add them. "])), (_l()(), i1.ɵeld(32, 0, null, null, 2, "div", [["class", "title"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = ((_co.activeQuestion = 4) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(33, 0, null, null, 0, "i", [["class", "dropdown icon"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Can I play with any mods? "])), (_l()(), i1.ɵeld(35, 0, null, null, 2, "div", [["class", "content"]], [[2, "active", null]], null, null, null, null)), (_l()(), i1.ɵeld(36, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Every round is currently freemod. This means you can play with any mods except mods that are not submittable (e.g. AutoPlay / ScoreV2, etc). However, keep in mind that if you select a difficulty reduction mod like NoFail, your score will be drastically reduce and your chances of passing the round will be lower compared to people who pass without difficulty reduction mods. "])), (_l()(), i1.ɵeld(38, 0, null, null, 2, "div", [["class", "title"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = ((_co.activeQuestion = 5) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(39, 0, null, null, 0, "i", [["class", "dropdown icon"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" How many players pass/fail each round? "])), (_l()(), i1.ɵeld(41, 0, null, null, 2, "div", [["class", "content"]], [[2, "active", null]], null, null, null, null)), (_l()(), i1.ɵeld(42, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" A certain percent of players will fail each round. The percent value used depends on which round the match is currently in. It also varies with how many players are in the game, and attempts to make the total number of rounds close to 10. Players will fail "])), (_l()(), i1.ɵeld(44, 0, null, null, 2, "div", [["class", "title"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = ((_co.activeQuestion = 6) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(45, 0, null, null, 0, "i", [["class", "dropdown icon"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" How are players ranked in each match? "])), (_l()(), i1.ɵeld(47, 0, null, null, 10, "div", [["class", "content"]], [[2, "active", null]], null, null, null, null)), (_l()(), i1.ɵeld(48, 0, null, null, 4, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" After each round, players who set a score will be ranked using "])), (_l()(), i1.ɵeld(50, 0, null, null, 1, "a", [["href", "https://en.wikipedia.org/wiki/Ranking#Standard_competition_ranking_(%221224%22_ranking)"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Standard competition ranking"])), (_l()(), i1.ɵted(-1, null, [". This means that scores tied will each gain the same rank (i.e. there can be multiple players with the same rank if they get the exact same score on a round). "])), (_l()(), i1.ɵeld(53, 0, null, null, 4, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Players who fail to set a score will be ranked according to "])), (_l()(), i1.ɵeld(55, 0, null, null, 1, "a", [["href", "https://en.wikipedia.org/wiki/Ranking#Modified_competition_ranking_(%221334%22_ranking)"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Modified competition ranking"])), (_l()(), i1.ɵted(-1, null, [". In other words, all players who did not set a score will be given the lowest possible rank out of all remaining players in the match. "])), (_l()(), i1.ɵeld(58, 0, null, null, 2, "div", [["class", "title"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = ((_co.activeQuestion = 7) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(59, 0, null, null, 0, "i", [["class", "dropdown icon"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" How does the skill rating system work? "])), (_l()(), i1.ɵeld(61, 0, null, null, 4, "div", [["class", "content"]], [[2, "active", null]], null, null, null, null)), (_l()(), i1.ɵeld(62, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" The ranking system is a custom implementation of the TrueSkill algorithm. Player ratings are calculated at the end of each match based on rank achieved in the match. Each new player starts with an initial rating between 950 and 1250 (this is based on their pp rank), and will either go up or down in rating after each match. The rating system attempts to judge player's skill and consistency rather than the number of games player, so below average skilled players will drop in rating, while above average players will gain rating. "])), (_l()(), i1.ɵeld(64, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Players who quit the match or fail to set a score during the round will still lose points and will be judged according to the rank they achieved in the match in the same way as every other player. "])), (_l()(), i1.ɵeld(66, 0, null, null, 2, "div", [["class", "title"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = ((_co.activeQuestion = 8) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(67, 0, null, null, 0, "i", [["class", "dropdown icon"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" How do lower rank lobbies affect skill gains/losses? "])), (_l()(), i1.ɵeld(69, 0, null, null, 2, "div", [["class", "content"]], [[2, "active", null]], null, null, null, null)), (_l()(), i1.ɵeld(70, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Currently, rating change for lower ranked lobbies is 1/5th that of normal lobies. This means that players in lower ranked lobbies will gain and lose rating slower than in non rank restricted lobbies. This amount is subject to change based on how it performs. "])), (_l()(), i1.ɵeld(72, 0, null, null, 2, "div", [["class", "title"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = ((_co.activeQuestion = 9) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(73, 0, null, null, 0, "i", [["class", "dropdown icon"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" How many rounds are played each match? "])), (_l()(), i1.ɵeld(75, 0, null, null, 2, "div", [["class", "content"]], [[2, "active", null]], null, null, null, null)), (_l()(), i1.ɵeld(76, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" A maximum of 10 rounds will be played, however in the majority of cases matches will not last as long as 10 rounds. The average number of rounds players in a match is about 7 (assuming everyone continues to the end). Matches with a large number of players (>500) may continue to round 9 or 10. "])), (_l()(), i1.ɵeld(78, 0, null, null, 2, "div", [["class", "title"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = ((_co.activeQuestion = 10) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(79, 0, null, null, 0, "i", [["class", "dropdown icon"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" How can a match end with no winner? "])), (_l()(), i1.ɵeld(81, 0, null, null, 8, "div", [["class", "content"]], [[2, "active", null]], null, null, null, null)), (_l()(), i1.ɵeld(82, 0, null, null, 5, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" The conditions for a match to end with no winner are as follows: "])), (_l()(), i1.ɵeld(84, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" - All of the alive players fail to set a score and are eliminated "])), (_l()(), i1.ɵeld(86, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" - The game makes it to 10 rounds and the final two players tie scores at the end (highly unlikely) "])), (_l()(), i1.ɵeld(88, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Note that even matches with no winner are still ranked and player ratings will be adjusted according to the results. "])), (_l()(), i1.ɵeld(90, 0, null, null, 2, "div", [["class", "title"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = ((_co.activeQuestion = 11) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(91, 0, null, null, 0, "i", [["class", "dropdown icon"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" What achievements are there and how are they obtained? "])), (_l()(), i1.ɵeld(93, 0, null, null, 4, "div", [["class", "content"]], [[2, "active", null]], null, null, null, null)), (_l()(), i1.ɵeld(94, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" There are currently multiple achievements that are possible to achieve. Achievements are normally rewarded after the match if they have been achieved by a player. "])), (_l()(), i1.ɵeld(96, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Achievements will continue to be added as time goes on, however the list of possible achievements is not available publicly at this time. "])), (_l()(), i1.ɵeld(98, 0, null, null, 2, "div", [["class", "title"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = ((_co.activeQuestion = 12) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(99, 0, null, null, 0, "i", [["class", "dropdown icon"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Are other game modes (mania/taiko/ctb etc) supported? "])), (_l()(), i1.ɵeld(101, 0, null, null, 2, "div", [["class", "content"]], [[2, "active", null]], null, null, null, null)), (_l()(), i1.ɵeld(102, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Yes! You can find these lobbies by checking the checkboxes on the main lobby screen. "])), (_l()(), i1.ɵeld(104, 0, null, null, 2, "div", [["class", "title"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = ((_co.activeQuestion = 13) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(105, 0, null, null, 0, "i", [["class", "dropdown icon"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Can I create my own lobbies? "])), (_l()(), i1.ɵeld(107, 0, null, null, 4, "div", [["class", "content"]], [[2, "active", null]], null, null, null, null)), (_l()(), i1.ɵeld(108, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" We will add the ability to create custom lobbies at some point in the future, where you will be able to choose your own map pool, and set the lobby to be either private (invite-only) or public. This feature will probably be a paid-only feature or will be given to specific players in the community for hosting specific tournaments. "])), (_l()(), i1.ɵeld(110, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Custom lobbies will also have the ability to limit players by country or rank (for example, a country-only tournament). "])), (_l()(), i1.ɵeld(112, 0, null, null, 2, "div", [["class", "title"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = ((_co.activeQuestion = 14) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(113, 0, null, null, 0, "i", [["class", "dropdown icon"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Is this open-source? Can I contribute? "])), (_l()(), i1.ɵeld(115, 0, null, null, 5, "div", [["class", "content"]], [[2, "active", null]], null, null, null, null)), (_l()(), i1.ɵeld(116, 0, null, null, 4, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Yes, and yes! You can always contribute to the source code "])), (_l()(), i1.ɵeld(118, 0, null, null, 1, "a", [["href", "https://github.com/Hughp135/osustuff-tournaments"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["on GitHub"])), (_l()(), i1.ɵted(-1, null, [". "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.activeQuestion === 1); _ck(_v, 8, 0, currVal_0); var currVal_1 = (_co.activeQuestion === 2); _ck(_v, 16, 0, currVal_1); var currVal_2 = (_co.activeQuestion === 3); _ck(_v, 24, 0, currVal_2); var currVal_3 = (_co.activeQuestion === 4); _ck(_v, 35, 0, currVal_3); var currVal_4 = (_co.activeQuestion === 5); _ck(_v, 41, 0, currVal_4); var currVal_5 = (_co.activeQuestion === 6); _ck(_v, 47, 0, currVal_5); var currVal_6 = (_co.activeQuestion === 7); _ck(_v, 61, 0, currVal_6); var currVal_7 = (_co.activeQuestion === 8); _ck(_v, 69, 0, currVal_7); var currVal_8 = (_co.activeQuestion === 9); _ck(_v, 75, 0, currVal_8); var currVal_9 = (_co.activeQuestion === 10); _ck(_v, 81, 0, currVal_9); var currVal_10 = (_co.activeQuestion === 11); _ck(_v, 93, 0, currVal_10); var currVal_11 = (_co.activeQuestion === 12); _ck(_v, 101, 0, currVal_11); var currVal_12 = (_co.activeQuestion === 13); _ck(_v, 107, 0, currVal_12); var currVal_13 = (_co.activeQuestion === 14); _ck(_v, 115, 0, currVal_13); }); }
export function View_FaqComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-faq", [], null, null, null, View_FaqComponent_0, RenderType_FaqComponent)), i1.ɵdid(1, 114688, null, 0, i2.FaqComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var FaqComponentNgFactory = i1.ɵccf("app-faq", i2.FaqComponent, View_FaqComponent_Host_0, {}, {}, []);
export { FaqComponentNgFactory as FaqComponentNgFactory };
