import * as tslib_1 from "tslib";
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { BehaviorSubject } from 'rxjs';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var ApiService = /** @class */ (function () {
    function ApiService(http) {
        this.http = http;
        this.BASE_URL = environment.base_api_url;
        this.connectionLost = new BehaviorSubject(false);
        this.baseHeaders = {
            'Content-Type': 'application/json',
            Accept: 'application/json'
        };
    }
    ApiService.prototype.get = function (url, options) {
        if (options === void 0) { options = {}; }
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var result, e_1;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.http.get("" + this.BASE_URL + url, {
                                headers: new HttpHeaders(tslib_1.__assign({}, this.baseHeaders, options.headers)),
                                params: new HttpParams({ fromObject: options.query })
                            }).toPromise()];
                    case 1:
                        result = _a.sent();
                        if (this.connectionLost.getValue() === true) {
                            this.connectionLost.next(false);
                        }
                        return [2 /*return*/, result];
                    case 2:
                        e_1 = _a.sent();
                        if (e_1.status === 504) {
                            this.connectionLost.next(true);
                        }
                        throw e_1;
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    ApiService.prototype.post = function (url, data, options) {
        if (options === void 0) { options = {}; }
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.http.post("" + this.BASE_URL + url, data, {
                            headers: new HttpHeaders(tslib_1.__assign({}, this.baseHeaders, options.headers))
                        }).toPromise()];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    ApiService.prototype.put = function (url, data, options) {
        if (options === void 0) { options = {}; }
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.http.put("" + this.BASE_URL + url, data, {
                            headers: new HttpHeaders(tslib_1.__assign({}, this.baseHeaders, options.headers))
                        }).toPromise()];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    ApiService.prototype.patch = function (url, data, options) {
        if (options === void 0) { options = {}; }
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.http.patch("" + this.BASE_URL + url, data, {
                            headers: new HttpHeaders(tslib_1.__assign({}, this.baseHeaders, options.headers))
                        }).toPromise()];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    ApiService.prototype.delete = function (url, options) {
        if (options === void 0) { options = {}; }
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.http.delete("" + this.BASE_URL + url, {
                            headers: new HttpHeaders(tslib_1.__assign({}, this.baseHeaders, options.headers)),
                            params: new HttpParams({ fromObject: options.query })
                        }).toPromise()];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    ApiService.ngInjectableDef = i0.defineInjectable({ factory: function ApiService_Factory() { return new ApiService(i0.inject(i1.HttpClient)); }, token: ApiService, providedIn: "root" });
    return ApiService;
}());
export { ApiService };
