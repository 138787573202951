/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./notifications.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "ng2-semantic-ui/dist/modules/transition/directives/transition";
import * as i4 from "./notifications.component";
import * as i5 from "../services/api.service";
import * as i6 from "../services/settings.service";
var styles_NotificationsComponent = [i0.styles];
var RenderType_NotificationsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_NotificationsComponent, data: {} });
export { RenderType_NotificationsComponent as RenderType_NotificationsComponent };
function View_NotificationsComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "center aligned ui inverted segment achievement-container"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.dismissAchievement() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "i", [["class", "big icon"]], null, null, null, null, null)), i1.ɵdid(3, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), (_l()(), i1.ɵeld(4, 0, null, null, 1, "h3", [["class", "ui sub header"]], null, null, null, null, null)), (_l()(), i1.ɵted(5, null, [" ", " "])), (_l()(), i1.ɵted(6, null, [" ", " "]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = "big icon"; var currVal_1 = _co.achievement.icon; _ck(_v, 3, 0, currVal_0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.achievement.title; _ck(_v, 5, 0, currVal_2); var currVal_3 = _co.achievement.description; _ck(_v, 6, 0, currVal_3); }); }
export function View_NotificationsComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "ui padded inverted segment notification-container"]], [[2, "transition", null], [2, "visible", null], [2, "hidden", null]], null, null, null, null)), i1.ɵdid(1, 16384, null, 0, i3.SuiTransition, [i1.Renderer2, i1.ElementRef, i1.ChangeDetectorRef], { suiTransition: [0, "suiTransition"] }, null), (_l()(), i1.ɵeld(2, 0, null, null, 1, "h4", [["class", "ui center aligned header"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Achievement Unlocked!"])), (_l()(), i1.ɵeld(4, 0, null, null, 0, "i", [["class", "times link icon close"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.dismissAchievement() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_NotificationsComponent_1)), i1.ɵdid(6, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_3 = _co.transitionController; _ck(_v, 1, 0, currVal_3); var currVal_4 = _co.achievement; _ck(_v, 6, 0, currVal_4); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).transitionClass; var currVal_1 = i1.ɵnov(_v, 1).isVisible; var currVal_2 = i1.ɵnov(_v, 1).isHidden; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2); }); }
export function View_NotificationsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-notifications", [], null, null, null, View_NotificationsComponent_0, RenderType_NotificationsComponent)), i1.ɵdid(1, 114688, null, 0, i4.NotificationsComponent, [i5.ApiService, i6.SettingsService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var NotificationsComponentNgFactory = i1.ɵccf("app-notifications", i4.NotificationsComponent, View_NotificationsComponent_Host_0, {}, {}, []);
export { NotificationsComponentNgFactory as NotificationsComponentNgFactory };
