<div class="ui inverted basic segment no-bg">
  <h3 *ngIf="game.status === 'round-over' && !myScore && inGame">
    <p>
      You did not set a score this round.
    </p>
  </h3>
  <h3 *ngIf="myScore">Your best score</h3>
  <div *ngIf="myScore">
    <app-scores-table [scores]="[myScore]" [currentUser]="currentUser" [game]="game"></app-scores-table>
  </div>
  <h3>Round {{ game.roundNumber }} scores</h3>
  <div class="ui active centered inline inverted medium teal text loader" *ngIf="game.status === 'checking-scores' && !scores.length">
    Checking scores...
  </div>
  <div class="scores-container" *ngIf="scores">
    <app-scores-table *ngIf="game.status === 'round-over' || scores.length" [scores]="scores" [currentUser]="currentUser" [game]="game"></app-scores-table>
  </div>
  <p class="m-top" *ngIf="playersNoScore.length">
    The following players failed to set a score for this round in time: {{ playersNoScore.join(', ') }}
  </p>
</div>
