import { OnInit, OnChanges } from '@angular/core';
var ScoresListComponent = /** @class */ (function () {
    function ScoresListComponent() {
        this.playersNoScore = [];
    }
    ScoresListComponent.prototype.ngOnInit = function () { };
    ScoresListComponent.prototype.ngOnChanges = function () {
        var _this = this;
        this.playersNoScore = this.players
            .filter(function (p) {
            return p.roundLostOn === _this.game.roundNumber &&
                !_this.scores.some(function (s) { return s.username === p.username; });
        })
            .map(function (p) { return p.username; });
    };
    Object.defineProperty(ScoresListComponent.prototype, "myScore", {
        get: function () {
            var _this = this;
            return this.scores.find(function (s) { return s.username === _this.currentUser; });
        },
        enumerable: true,
        configurable: true
    });
    return ScoresListComponent;
}());
export { ScoresListComponent };
