import * as tslib_1 from "tslib";
import { GameService } from './../../game.service';
import { ActivatedRoute, Router } from '@angular/router';
import { OnInit } from '@angular/core';
import { gameModeOpts } from '../lobby-form/lobby-form.component';
var EditLobbyComponent = /** @class */ (function () {
    function EditLobbyComponent(route, router, gameService) {
        this.route = route;
        this.router = router;
        this.gameService = gameService;
        this.creating = false;
        this.editGame = this.doEditGame.bind(this);
        this.deleting = false;
    }
    EditLobbyComponent.prototype.ngOnInit = function () {
        var data = (this.route.snapshot.data).data;
        var game = data.lobby;
        var beatmaps = data.beatmaps;
        this.game = game;
        var startDate = game.nextStageStarts ? new Date(game.nextStageStarts) : new Date(Date.now() + 60000);
        var timezoneOffset = -Math.round(new Date().getTimezoneOffset() / 60);
        this.formData = {
            startDate: startDate,
            title: game.title,
            description: game.description,
            timezoneOffset: timezoneOffset,
            minPlayers: 4,
            maxPlayers: 250,
            setMinRank: !!game.minRank,
            setMaxRank: !!game.maxRank,
            minRank: game.minRank,
            maxRank: game.maxRank,
            roundBeatmaps: beatmaps.map(function (b) { return ({
                beatmapId: parseInt(b.beatmap_id, 10),
                beatmap: b,
            }); }),
            gameMode: gameModeOpts[game.gameMode],
        };
    };
    EditLobbyComponent.prototype.deleteGame = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var e_1;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.deleting = true;
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, this.gameService.deleteLobby(this.game._id)];
                    case 2:
                        _a.sent();
                        this.router.navigate(['/lobbies']);
                        return [3 /*break*/, 4];
                    case 3:
                        e_1 = _a.sent();
                        if (e_1.error && e_1.error.error) {
                            this.error = e_1.error.error;
                        }
                        else {
                            if (e_1.status === 401) {
                                this.error = 'You do not have the permissions to delete this lobby';
                            }
                            else {
                                this.error = 'Failed to delete game';
                                console.error(e_1);
                            }
                        }
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    EditLobbyComponent.prototype.doEditGame = function (formData) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var options, gameId, e_2;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        options = {
                            nextStageStarts: formData.startDate,
                            title: formData.title,
                            description: formData.description,
                            minPlayers: formData.minPlayers,
                            maxPlayers: formData.maxPlayers,
                            roundBeatmaps: formData.roundBeatmaps.map(function (r) { return r.beatmap; }),
                            minRank: formData.setMinRank ? formData.minRank : undefined,
                            maxRank: formData.setMaxRank ? formData.maxRank : undefined,
                            gameMode: formData.gameMode.value,
                            password: formData.password,
                        };
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, this.gameService.editGame(this.game._id, options)];
                    case 2:
                        gameId = (_a.sent()).gameId;
                        this.router.navigate(['/lobbies', gameId]);
                        return [3 /*break*/, 4];
                    case 3:
                        e_2 = _a.sent();
                        this.creating = false;
                        if (e_2.error && e_2.error.error) {
                            this.error = e_2.error.error;
                        }
                        else {
                            if (e_2.status === 401) {
                                this.error = 'You do not have the permissions to create a lobby';
                            }
                            else {
                                this.error = 'Failed to create game';
                                console.error(e_2);
                            }
                        }
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    return EditLobbyComponent;
}());
export { EditLobbyComponent };
