/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./create-lobby.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../lobby-form/lobby-form.component.ngfactory";
import * as i3 from "../lobby-form/lobby-form.component";
import * as i4 from "../../services/api.service";
import * as i5 from "./create-lobby.component";
import * as i6 from "../../game.service";
import * as i7 from "@angular/router";
var styles_CreateLobbyComponent = [i0.styles];
var RenderType_CreateLobbyComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CreateLobbyComponent, data: {} });
export { RenderType_CreateLobbyComponent as RenderType_CreateLobbyComponent };
export function View_CreateLobbyComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["class", "ui inverted container section"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "h1", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Create a new Lobby"])), (_l()(), i1.ɵeld(3, 0, null, null, 0, "div", [["class", "ui divider"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "app-lobby-form", [], null, null, null, i2.View_LobbyFormComponent_0, i2.RenderType_LobbyFormComponent)), i1.ɵdid(5, 114688, null, 0, i3.LobbyFormComponent, [i4.ApiService], { onSubmit: [0, "onSubmit"], creating: [1, "creating"], error: [2, "error"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.createGame; var currVal_1 = _co.creating; var currVal_2 = _co.error; _ck(_v, 5, 0, currVal_0, currVal_1, currVal_2); }, null); }
export function View_CreateLobbyComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-create-lobby", [], null, null, null, View_CreateLobbyComponent_0, RenderType_CreateLobbyComponent)), i1.ɵdid(1, 114688, null, 0, i5.CreateLobbyComponent, [i6.GameService, i7.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var CreateLobbyComponentNgFactory = i1.ɵccf("app-create-lobby", i5.CreateLobbyComponent, View_CreateLobbyComponent_Host_0, {}, {}, []);
export { CreateLobbyComponentNgFactory as CreateLobbyComponentNgFactory };
