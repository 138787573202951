<div class="ui container">
  <div class="ui inverted very padded segment">
    <h1>Log in to play...</h1>
    <p>
      Click the button below to log in with your osu! account to begin playing!
    </p>
    <a class="ui primary button" [href]="loginLink">
      Log in via osu!
    </a>
  </div>
</div>
