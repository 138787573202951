<div class="ui inverted segment no-bg">
  <div class="ui equal width inverted divided grid">
    <div class="one wide column">
      <a [routerLink]="['/lobbies']">
        <i class="large angle left icon"></i>
      </a>
    </div>
    <div class="column">
      <h5>Lobby Name</h5>
      {{ game.title }}
    </div>
    <div class="column">
      <h5>Status</h5>
      {{ status }}
    </div>
    <div class="column" *ngIf="game.status === 'complete'">
      <h5>Winner</h5>
      {{ game.winningUser ? game.winningUser.username : 'No one won!' }}
    </div>
    <div class="column">
      <h5>Round{{ game.status === 'complete' ? 's' : '' }}</h5>
      {{ game.roundNumber || '-' }}
    </div>
    <div class="column" *ngIf="game.status !== 'complete'">
      <h5>{{ nextStageStartsMsg }}</h5>
      {{ game.status !== 'checking-scores' ? timeLeft : ''}}
    </div>
    <div class="column" *ngIf="canEdit">
      <a class="ui primary button" [routerLink]="['/lobbies', game._id, 'edit']">
        Edit Game
      </a>
    </div>
    <div class="column" *ngIf="game.status === 'new' && inGame">
      <div class="ui red button" (click)="leaveGame()">Leave Game</div>
    </div>
    <div class="center aligned column" *ngIf="connectionLost"  suiPopup popupInverted="true" popupText="The server may be restarting or down for maintenance.">
      <h5>Connection lost...</h5>
      <i class="red notched circle loading icon"></i>
    </div>
  </div>
</div>
