<div class="ui inverted segment">
  <div *ngIf="canJoin">
    <div *ngIf="!loggedIn">
      <a class="ui green button" [href]="loginLink">
        Join Game
      </a>
      Your osu! account will be authorized.
    </div>
    <div *ngIf="loggedIn">
      <div *ngIf="inAnotherGame">
        <p>You are in another game, so you must leave before you
          can join. Are you sure you want to leave another game to join this one?</p>
        <div class="ui tiny orange button" (click)="leaveGame()">
          Leave other game
        </div>
      </div>
      <div *ngIf="!inAnotherGame">
        <div *ngIf="!success">
          <div class="ui icon input password-input" *ngIf="game.hasPassword">
            <input type="password" placeholder="Enter Password..." [(ngModel)]="password">
            <i class="lock icon"></i>
          </div>
          <div class="small action input">
            <button class="ui green button" [disabled]="requestingJoin || noPasswordEntered || isBanned"
              [class.loading]="requestingJoin" (click)="joinGame()">
              Join Game
            </button>
            {{ isBanned ? 'You cannot join; you are banned.' : 'Make sure you are ready to play before joining.' }}
          </div>

          <div class="ui red inverted segment" *ngIf="error">
            <p>{{ error }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="!canJoin">
    This game is in progress. You cannot join a game that has
    already started.
  </div>
</div>
