import * as tslib_1 from "tslib";
import { ApiService } from './../../services/api.service';
import { OnInit } from '@angular/core';
import { SettingsService } from 'src/app/services/settings.service';
var AchievementsComponent = /** @class */ (function () {
    function AchievementsComponent(apiService, settingsService) {
        var _this = this;
        this.apiService = apiService;
        this.subscriptions = [];
        this.run(); // work around for not being able to use async in constructor
        // most likely not the best practice, TODO: fix?
        this.subscriptions = [
            settingsService.user.subscribe(function (u) { return (_this.currentUser = u); })
        ];
    }
    AchievementsComponent.prototype.ngOnInit = function () { };
    AchievementsComponent.prototype.run = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var apiAchievements, e_1;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.apiService.get('achievements/get-all-achievements')];
                    case 1:
                        apiAchievements = _a.sent();
                        this.achievements = apiAchievements;
                        return [3 /*break*/, 3];
                    case 2:
                        e_1 = _a.sent();
                        console.error(e_1);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    Object.defineProperty(AchievementsComponent.prototype, "loggedIn", {
        get: function () {
            return this.currentUser && this.currentUser.username;
        },
        enumerable: true,
        configurable: true
    });
    return AchievementsComponent;
}());
export { AchievementsComponent };
