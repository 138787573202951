import * as tslib_1 from "tslib";
import { OnInit, OnDestroy } from '@angular/core';
import { SettingsService } from 'src/app/services/settings.service';
import { Router } from '@angular/router';
import { ApiService } from '../../../services/api.service';
var GameInfoComponent = /** @class */ (function () {
    function GameInfoComponent(settingsService, router, apiService) {
        this.settingsService = settingsService;
        this.router = router;
        this.apiService = apiService;
    }
    GameInfoComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.subscription = this.apiService.connectionLost.subscribe(function (val) { return _this.connectionLost = val; });
    };
    GameInfoComponent.prototype.ngOnDestroy = function () {
        this.subscription.unsubscribe();
    };
    Object.defineProperty(GameInfoComponent.prototype, "status", {
        get: function () {
            switch (this.game.status) {
                case 'scheduled':
                    return 'Scheduled';
                case 'new':
                    return this.game.nextStageStarts
                        ? 'About to start...'
                        : 'Waiting for more players';
                case 'in-progress':
                    return "In Progress";
                case 'checking-scores':
                    return 'Checking scores';
                case 'round-over':
                    return 'Round complete';
                case 'complete':
                    return 'Finished';
                default:
                    return this.game.status;
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(GameInfoComponent.prototype, "nextStageStartsMsg", {
        get: function () {
            switch (this.game.status) {
                case 'scheduled':
                    return 'Game open to join in';
                case 'new':
                    return 'Game will start in';
                case 'in-progress':
                    return "Round " + this.game.roundNumber + " ends in";
                case 'checking-scores':
                    return 'Checking scores';
                case 'round-over':
                    return "Round " + (this.game.roundNumber + 1) + " starts in";
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(GameInfoComponent.prototype, "canEdit", {
        get: function () {
            return this.isOwner && ['new', 'scheduled'].includes(this.game.status);
        },
        enumerable: true,
        configurable: true
    });
    GameInfoComponent.prototype.leaveGame = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.settingsService.leaveGame(this.game._id)];
                    case 1:
                        _a.sent();
                        this.router.navigate(['/lobbies']);
                        return [2 /*return*/];
                }
            });
        });
    };
    return GameInfoComponent;
}());
export { GameInfoComponent };
