import * as tslib_1 from "tslib";
import { ApiService } from './api.service';
import { BehaviorSubject } from 'rxjs';
import * as i0 from "@angular/core";
import * as i1 from "./api.service";
var SettingsService = /** @class */ (function () {
    function SettingsService(apiService) {
        var _this = this;
        this.apiService = apiService;
        this.currentGame = new BehaviorSubject(undefined);
        this.user = new BehaviorSubject(undefined);
        this.checkCurrentGame();
        window.adminLogon = function (password) {
            _this.setAdmin(password);
        };
    }
    SettingsService.prototype.checkCurrentGame = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var me, e_1;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.apiService.get("user/me")];
                    case 1:
                        me = _a.sent();
                        if (me) {
                            this.user.next(me);
                            if (me.currentGame) {
                                this.setCurrentGame(me.currentGame);
                            }
                            else {
                                this.clearCurrentGame();
                            }
                        }
                        else {
                            this.clearCurrentGame();
                        }
                        return [3 /*break*/, 3];
                    case 2:
                        e_1 = _a.sent();
                        if ([404, 408, 401].includes(e_1.status)) {
                            this.clearCurrentGame();
                        }
                        else {
                            console.error(e_1);
                        }
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    SettingsService.prototype.setCurrentGame = function (gameId) {
        var currentGame = {
            gameId: gameId,
        };
        localStorage.setItem('currentGame', JSON.stringify(currentGame));
        this.currentGame.next(currentGame);
    };
    SettingsService.prototype.clearCurrentGame = function () {
        localStorage.removeItem('currentGame');
        if (this.currentGame.getValue() !== undefined) {
            this.currentGame.next(undefined);
        }
    };
    SettingsService.prototype.setAdmin = function (password) {
        localStorage.setItem('adminPw', password);
    };
    SettingsService.prototype.setGameModes = function (enabledGameModes) {
        localStorage.setItem('enabledGameModes', JSON.stringify(enabledGameModes));
    };
    SettingsService.prototype.getGameModes = function () {
        return JSON.parse(localStorage.getItem('enabledGameModes') || '["0"]');
    };
    Object.defineProperty(SettingsService.prototype, "adminPw", {
        get: function () {
            return localStorage.getItem('adminPw');
        },
        enumerable: true,
        configurable: true
    });
    SettingsService.prototype.leaveGame = function (gameId) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var currentGame;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        currentGame = this.currentGame.getValue();
                        if (!currentGame) {
                            return [2 /*return*/];
                        }
                        return [4 /*yield*/, this.apiService.post("lobbies/" + gameId + "/leave", {})];
                    case 1:
                        _a.sent();
                        this.clearCurrentGame();
                        return [2 /*return*/];
                }
            });
        });
    };
    SettingsService.ngInjectableDef = i0.defineInjectable({ factory: function SettingsService_Factory() { return new SettingsService(i0.inject(i1.ApiService)); }, token: SettingsService, providedIn: "root" });
    return SettingsService;
}());
export { SettingsService };
