/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./match-results.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "ng2-semantic-ui/dist/modules/dropdown/directives/dropdown-menu";
import * as i3 from "../scores-list/scores-table/scores-table.component.ngfactory";
import * as i4 from "../scores-list/scores-table/scores-table.component";
import * as i5 from "@angular/common";
import * as i6 from "../beatmap-info/beatmap-info.component.ngfactory";
import * as i7 from "../beatmap-info/beatmap-info.component";
import * as i8 from "@angular/platform-browser";
import * as i9 from "./match-results.component";
import * as i10 from "../../../game.service";
var styles_MatchResultsComponent = [i0.styles];
var RenderType_MatchResultsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_MatchResultsComponent, data: {} });
export { RenderType_MatchResultsComponent as RenderType_MatchResultsComponent };
function View_MatchResultsComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "ui primary button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.hideResults() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "i", [["class", "left angle icon"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" View live game "]))], null, null); }
function View_MatchResultsComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "a", [["class", "item"]], [[2, "active", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.showRound(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 16384, null, 1, i2.SuiDropdownMenuItem, [i1.Renderer2, i1.ElementRef], null, null), i1.ɵqud(335544320, 1, { childDropdownMenu: 0 }), (_l()(), i1.ɵted(3, null, [" Round ", " ", " "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.round && (_co.round.roundNumber === _v.context.$implicit)); _ck(_v, 0, 0, currVal_0); var currVal_1 = _v.context.$implicit; var currVal_2 = ((_co.finalRound === _v.context.$implicit) ? "- Final" : ""); _ck(_v, 3, 0, currVal_1, currVal_2); }); }
function View_MatchResultsComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" This round has no scores submitted "]))], null, null); }
function View_MatchResultsComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-scores-table", [], null, null, null, i3.View_ScoresTableComponent_0, i3.RenderType_ScoresTableComponent)), i1.ɵdid(1, 114688, null, 0, i4.ScoresTableComponent, [], { scores: [0, "scores"], game: [1, "game"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.round.scores; var currVal_1 = _co.game; _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
function View_MatchResultsComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "h3", [], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["Round ", " scores"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_MatchResultsComponent_6)), i1.ɵdid(4, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.round; _ck(_v, 4, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.round.roundNumber; _ck(_v, 2, 0, currVal_0); }); }
function View_MatchResultsComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "h3", [], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "app-beatmap-info", [], null, null, null, i6.View_BeatmapInfoComponent_0, i6.RenderType_BeatmapInfoComponent)), i1.ɵdid(3, 114688, null, 0, i7.BeatmapInfoComponent, [i8.DomSanitizer], { game: [0, "game"], beatmap: [1, "beatmap"], hideTitle: [2, "hideTitle"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_MatchResultsComponent_4)), i1.ɵdid(5, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_MatchResultsComponent_5)), i1.ɵdid(7, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.game; var currVal_1 = _co.round.beatmap; var currVal_2 = true; _ck(_v, 3, 0, currVal_0, currVal_1, currVal_2); var currVal_3 = (!_co.round.scores || !_co.round.scores.length); _ck(_v, 5, 0, currVal_3); var currVal_4 = (_co.round.scores && _co.round.scores.length); _ck(_v, 7, 0, currVal_4); }, null); }
export function View_MatchResultsComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 12, "div", [["class", "ui inverted segment no-bg"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 6, "div", [["class", "ui stackable grid"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "div", [["class", "ten wide column"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "h3", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Match Results"])), (_l()(), i1.ɵeld(5, 0, null, null, 2, "div", [["class", "six wide right aligned column"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_MatchResultsComponent_1)), i1.ɵdid(7, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(8, 0, null, null, 2, "div", [["class", "ui inverted secondary menu"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_MatchResultsComponent_2)), i1.ɵdid(10, 278528, null, 0, i5.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_MatchResultsComponent_3)), i1.ɵdid(12, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.game.status !== "complete"); _ck(_v, 7, 0, currVal_0); var currVal_1 = _co.rounds; _ck(_v, 10, 0, currVal_1); var currVal_2 = _co.round; _ck(_v, 12, 0, currVal_2); }, null); }
export function View_MatchResultsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-match-results", [], null, null, null, View_MatchResultsComponent_0, RenderType_MatchResultsComponent)), i1.ɵdid(1, 114688, null, 0, i9.MatchResultsComponent, [i10.GameService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var MatchResultsComponentNgFactory = i1.ɵccf("app-match-results", i9.MatchResultsComponent, View_MatchResultsComponent_Host_0, { game: "game", toggleViewResults: "toggleViewResults" }, {}, []);
export { MatchResultsComponentNgFactory as MatchResultsComponentNgFactory };
