<div class="ui inverted padded segment no-bg">
  <h3 class="ui header">Beatmap list</h3>
  <p>
    These are the maps that will be played on each round. Not
    all rounds
    will necessarily be played, however it is recommended to
    download
    them before the match starts if your download speed is low.
    There is a ~60 second break
    in between each round.
  </p>
</div>
<div class="ui inverted segment no-bg list-container">
  <div class="ui inverted relaxed divided list">
    <div class="item" *ngFor="let beatmap of beatmaps; let index = index;">
      <p class="title">Round {{index + 1}}</p>
      <div class="content">
        <div class="ui stackable two column grid">
          <div class="column">
            <a class="header" [href]="beatmapHref(beatmap)"
              target="_blank">
              <div class="beatmap-image-container">
                <img [src]="'//b.ppy.sh/thumb/' + beatmap.beatmapset_id + '.jpg'"
                  alt="">
              </div>
              {{ beatmap.artist }} - {{ beatmap.title }}
              <p>
                {{ beatmap.version }}
                &nbsp; <i class="fitted star outline icon"></i>
                {{
                starRating(beatmap) }}
                &nbsp; <i class="fitted stopwatch icon"></i> {{
                getDuration(beatmap) }}
              </p>
            </a>
          </div>
          <div class="right aligned column">
            <a class="ui primary tiny button"
              [href]="dlLink(beatmap)">
              <i class="download icon"></i>
              Download
            </a>
            <a class="ui primary tiny button" [href]="osuDirectLink(beatmap)">
              <i class="download icon"></i>
              osu!direct
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
