import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { GameService } from 'src/app/game.service';
var MatchResultsComponent = /** @class */ (function () {
    function MatchResultsComponent(gameService) {
        this.gameService = gameService;
        this.rounds = [];
    }
    MatchResultsComponent.prototype.ngOnInit = function () {
        this.rounds = new Array(this.game.roundNumber || 0).fill(null).map(function (_, i) { return i + 1; });
        if (this.game.roundNumber) {
            this.showRound(this.game.roundNumber);
        }
    };
    Object.defineProperty(MatchResultsComponent.prototype, "finalRound", {
        get: function () {
            return this.game.status === 'complete' ? this.game.roundNumber : undefined;
        },
        enumerable: true,
        configurable: true
    });
    MatchResultsComponent.prototype.showRound = function (round) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _a;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = this;
                        return [4 /*yield*/, this.gameService.getLobbyRound(this.game._id, round)];
                    case 1:
                        _a.round = _b.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    MatchResultsComponent.prototype.hideResults = function () {
        this.toggleViewResults();
    };
    return MatchResultsComponent;
}());
export { MatchResultsComponent };
