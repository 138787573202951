import * as tslib_1 from "tslib";
import { ApiService } from './api.service';
import { SettingsService } from './settings.service';
import * as i0 from "@angular/core";
import * as i1 from "./api.service";
import * as i2 from "./settings.service";
var AdminService = /** @class */ (function () {
    function AdminService(apiService, settingsService) {
        this.apiService = apiService;
        this.settingsService = settingsService;
    }
    AdminService.prototype.skipRound = function (gameId) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.apiService.post("lobbies/" + gameId + "/skip-round", {
                            pw: this.settingsService.adminPw,
                        })];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    AdminService.prototype.toggleFreeze = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.apiService.post("toggle-monitoring", { pw: this.settingsService.adminPw })];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    AdminService.prototype.clearDb = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.apiService.post("admin/clear-db", { pw: this.settingsService.adminPw })];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    AdminService.prototype.toggleAutoCreate = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.apiService.post("admin/toggle-autocreate", { pw: this.settingsService.adminPw })];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    AdminService.prototype.deleteLobby = function (gameId) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.apiService.post("admin/delete-lobby", { gameId: gameId })];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    AdminService.prototype.createTestUser = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.apiService.post("admin/create-test-user", { pw: this.settingsService.adminPw })];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    AdminService.ngInjectableDef = i0.defineInjectable({ factory: function AdminService_Factory() { return new AdminService(i0.inject(i1.ApiService), i0.inject(i2.SettingsService)); }, token: AdminService, providedIn: "root" });
    return AdminService;
}());
export { AdminService };
