import * as tslib_1 from "tslib";
import { WebsocketService } from './../../services/websocket.service';
import { SettingsService, } from './../../services/settings.service';
import { OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import * as Visibility from 'visibilityjs';
import { TransitionController, Transition, TransitionDirection, } from 'ng2-semantic-ui';
var GameLobbyComponent = /** @class */ (function () {
    function GameLobbyComponent(route, settingsService, socketService, router) {
        var _this = this;
        this.route = route;
        this.settingsService = settingsService;
        this.socketService = socketService;
        this.router = router;
        this.players = [];
        this.subscriptions = [];
        this.visibilityTimers = [];
        this.announcedStart = false;
        this.transitionController = new TransitionController();
        this.viewResults = false;
        this.toggleViewResults = function () {
            _this.viewResults = !_this.viewResults;
        };
        this.isAdmin = !!settingsService.adminPw;
    }
    GameLobbyComponent.prototype.ngOnInit = function () {
        var _this = this;
        var data = this.route.snapshot.data.data;
        if (!data) {
            return this.router.navigate(['/lobbies']);
        }
        // Map all the data to component state
        this.subscriptions = [
            data.lobby.subscribe(function (game) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                return tslib_1.__generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, this.announceRoundChanges(game)];
                        case 1:
                            _a.sent();
                            this.game = game;
                            return [2 /*return*/];
                    }
                });
            }); }),
            data.players.subscribe(function (players) { return (_this.players = players); }),
            data.timeLeft.subscribe(function (timeLeft) { return (_this.timeLeft = timeLeft); }),
            data.beatmaps.subscribe(function (beatmaps) { return (_this.beatmaps = beatmaps); }),
            this.settingsService.currentGame.subscribe(function (val) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                return tslib_1.__generator(this, function (_a) {
                    this.currentGame = val;
                    return [2 /*return*/];
                });
            }); }),
            this.settingsService.user.subscribe(function (val) { return (_this.currentUser = val); }),
        ];
        this.messages = data.messages;
    };
    GameLobbyComponent.prototype.ngOnDestroy = function () {
        this.visibilityTimers.forEach(function (v) { return Visibility.stop(v); });
        this.subscriptions.forEach(function (s) { return s.unsubscribe(); });
        if (this.currentGame &&
            this.currentGame.gameId === this.game._id &&
            this.game.status === 'complete') {
            this.settingsService.clearCurrentGame();
        }
        this.socketService.socket.disconnect();
    };
    GameLobbyComponent.prototype.announceRoundChanges = function (game) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var oldStatus, statusChanged, beatmap, regexReplace, winnerString, e_1;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!this.game) {
                            return [2 /*return*/];
                        }
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 5, , 6]);
                        oldStatus = this.game.status;
                        statusChanged = oldStatus !== game.status;
                        if (game.status === 'in-progress' &&
                            game.roundNumber !== this.game.roundNumber &&
                            this.inGame) {
                            beatmap = this.beatmaps[game.roundNumber - 1];
                            regexReplace = /[^\w\s-.=%@&+']/gi;
                            responsiveVoice.speak("Round " + game.roundNumber + " has started. The beatmap is " + beatmap.artist
                                .replace(regexReplace, ' ')
                                .toLowerCase() + " - " + beatmap.title
                                .replace(regexReplace, ' ')
                                .toLowerCase() + ", " + beatmap.version
                                .replace(regexReplace, ' ')
                                .toLowerCase());
                        }
                        if (((game.status === 'round-over' &&
                            !['round-over', 'checking-scores'].includes(this.game.status)) ||
                            (game.status === 'checking-scores' &&
                                this.game.status !== 'checking-scores')) &&
                            this.inGame) {
                            responsiveVoice.speak("Round " + game.roundNumber + " has ended.");
                        }
                        if (game.status === 'complete' && this.game.status !== 'complete') {
                            winnerString = game.winningUser
                                ? "The winner is " + game.winningUser.username
                                : 'There was no winner.';
                            responsiveVoice.speak("The match has finished. " + winnerString);
                        }
                        if (game.status === 'new' &&
                            game.secondsToNextRound <= 30 &&
                            !this.announcedStart &&
                            this.inGame) {
                            responsiveVoice.speak("The first round is starting in " + Math.floor(game.secondsToNextRound) + " seconds");
                            this.announcedStart = true;
                        }
                        this.game = game;
                        if (!(statusChanged && game.status !== 'round-over')) return [3 /*break*/, 4];
                        this.viewResults = false;
                        return [4 /*yield*/, this.animate(TransitionDirection.Out)];
                    case 2:
                        _a.sent();
                        return [4 /*yield*/, this.animate(TransitionDirection.In)];
                    case 3:
                        _a.sent();
                        _a.label = 4;
                    case 4: return [3 /*break*/, 6];
                    case 5:
                        e_1 = _a.sent();
                        console.error(e_1);
                        return [3 /*break*/, 6];
                    case 6: return [2 /*return*/];
                }
            });
        });
    };
    Object.defineProperty(GameLobbyComponent.prototype, "showBeatmap", {
        get: function () {
            var alivePlayers = this.players.filter(function (p) { return p.alive; });
            return (this.game &&
                !['new', 'complete', 'scheduled'].includes(this.game.status) &&
                !this.viewResults &&
                (alivePlayers.length > 1 || this.game.roundNumber === 1));
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(GameLobbyComponent.prototype, "showScores", {
        get: function () {
            return (this.game &&
                ['round-over', 'checking-scores'].includes(this.game.status) &&
                !this.viewResults);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(GameLobbyComponent.prototype, "inAnotherGame", {
        get: function () {
            return this.currentGame && this.currentGame.gameId !== this.game._id;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(GameLobbyComponent.prototype, "inGame", {
        get: function () {
            return this.currentGame && this.currentGame.gameId === this.game._id;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(GameLobbyComponent.prototype, "showJoinGame", {
        get: function () {
            return (!(this.mePlayer || this.inGame) &&
                (this.game && !['complete', 'scheduled'].includes(this.game.status)));
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(GameLobbyComponent.prototype, "showBeatmaps", {
        get: function () {
            return (this.game &&
                (['new', 'scheduled'].includes(this.game.status) || this.showBeatmapList));
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(GameLobbyComponent.prototype, "isAlive", {
        get: function () {
            if (this.mePlayer) {
                return this.mePlayer.alive;
            }
            else {
                return true;
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(GameLobbyComponent.prototype, "isOwner", {
        get: function () {
            return this.currentUser && this.currentUser._id === this.game.owner;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(GameLobbyComponent.prototype, "mePlayer", {
        get: function () {
            var _this = this;
            return (this.currentUsername &&
                this.players.find(function (p) { return p.username === _this.currentUsername; }));
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(GameLobbyComponent.prototype, "isModOrAdmin", {
        get: function () {
            return (this.currentUser &&
                this.currentUser.roles &&
                (this.currentUser.roles.includes('admin') ||
                    this.currentUser.roles.includes('moderator')));
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(GameLobbyComponent.prototype, "currentBeatmap", {
        get: function () {
            if (this.game.status === 'in-progress') {
                return this.game.round.beatmap;
            }
            return this.beatmaps[this.game.roundNumber];
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(GameLobbyComponent.prototype, "currentUsername", {
        get: function () {
            return this.currentUser && this.currentUser.username;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(GameLobbyComponent.prototype, "showDescription", {
        get: function () {
            return (this.game.description &&
                this.game &&
                ['new', 'scheduled'].includes(this.game.status));
        },
        enumerable: true,
        configurable: true
    });
    GameLobbyComponent.prototype.animate = function (direction) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, new Promise(function (res) {
                        var name = direction === TransitionDirection.Out ? 'fly right' : 'fly left';
                        _this.transitionController.animate(new Transition(name, 250, direction, function () {
                            res();
                        }));
                    })];
            });
        });
    };
    return GameLobbyComponent;
}());
export { GameLobbyComponent };
