import { OnInit } from '@angular/core';
var GameStatusComponent = /** @class */ (function () {
    function GameStatusComponent() {
    }
    GameStatusComponent.prototype.ngOnInit = function () { };
    GameStatusComponent.prototype.getTitleAndMessage = function () {
        if (this.game.status === 'scheduled') {
            return {
                title: 'Game is scheduled to start later',
                message: 'The game will be open to join in ' + this.timeLeft,
            };
        }
        if (this.game.status === 'new') {
            if (this.game.nextStageStarts) {
                return {
                    title: 'Game is starting soon',
                    message: "The game is starting in " + this.timeLeft,
                };
            }
            return {
                title: 'Waiting for players',
                message: "More players are needed before the game automatically starts (at least 4 players)." +
                    " Have you tried inviting some friends to join?",
            };
        }
        if (this.game.status === 'in-progress') {
            return {
                title: "Round " + this.game.roundNumber + " is now in progress!",
                message: "All players must now set a score on the map.",
            };
        }
        if (this.game.status === 'checking-scores') {
            return {
                title: "Checking scores...",
                message: 'Scores are now being checked, and the winners/loser will be decided',
            };
        }
        if (this.game.status === 'round-over') {
            return {
                title: "Round " + this.game.roundNumber + " has finished",
                message: "The next round starts in " + this.timeLeft,
            };
        }
        if ((this.game.status = 'complete')) {
            return {
                title: 'The game has finished!',
                message: this.game.winningUser
                    ? this.game.winningUser.username + " has won the match!"
                    : 'No one won this time. Either no one set a score in the final round or it was a draw.',
            };
        }
    };
    GameStatusComponent.prototype.toggleShowResults = function () {
        this.toggleViewResults();
    };
    Object.defineProperty(GameStatusComponent.prototype, "title", {
        get: function () {
            return this.getTitleAndMessage().title;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(GameStatusComponent.prototype, "message", {
        get: function () {
            return this.getTitleAndMessage().message;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(GameStatusComponent.prototype, "rank", {
        get: function () {
            return this.mePlayer && this.mePlayer.gameRank;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(GameStatusComponent.prototype, "isWinner", {
        get: function () {
            return (this.game.winningUser &&
                this.mePlayer &&
                this.game.winningUser.username === this.mePlayer.username);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(GameStatusComponent.prototype, "showAdditionalDescription", {
        get: function () {
            return ['new', 'scheduled'].includes(this.game.status);
        },
        enumerable: true,
        configurable: true
    });
    return GameStatusComponent;
}());
export { GameStatusComponent };
