import { OnInit } from '@angular/core';
import { getTimeComponents } from 'src/app/resolvers/game-lobby.resolver';
import { DomSanitizer } from '@angular/platform-browser';
var BeatmapInfoComponent = /** @class */ (function () {
    function BeatmapInfoComponent(sanitizer) {
        this.sanitizer = sanitizer;
    }
    BeatmapInfoComponent.prototype.ngOnInit = function () { };
    BeatmapInfoComponent.prototype.getBgStyles = function () {
        return (this.beatmap && {
            background: "linear-gradient( rgba(0, 0, 0, 0.6),\n            rgba(0, 0, 0, 0.7) ),\n            url(https://assets.ppy.sh/beatmaps/" + this.beatmap.beatmapset_id + "/covers/card@2x.jpg)",
        });
    };
    Object.defineProperty(BeatmapInfoComponent.prototype, "starRating", {
        get: function () {
            return parseFloat(this.beatmap.difficultyrating).toFixed(2);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(BeatmapInfoComponent.prototype, "beatmapHref", {
        get: function () {
            return (this.beatmap &&
                "https://osu.ppy.sh/beatmapsets/" + this.beatmap.beatmapset_id + "#" + getBeatmapHrefString(this.game.gameMode) + "/" + this.beatmap.beatmap_id);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(BeatmapInfoComponent.prototype, "dlLink", {
        get: function () {
            return (this.beatmap &&
                "https://osu.ppy.sh/beatmapsets/" + this.beatmap.beatmapset_id + "/download?noVideo=true");
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(BeatmapInfoComponent.prototype, "osuDirectLink", {
        get: function () {
            return (this.beatmap &&
                this.sanitizer.bypassSecurityTrustUrl("osu://dl/" + this.beatmap.beatmapset_id));
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(BeatmapInfoComponent.prototype, "duration", {
        get: function () {
            var millis = parseFloat(this.beatmap.total_length) * 1000;
            var _a = getTimeComponents(millis), minutes = _a.minutes, seconds = _a.seconds;
            return minutes + ":" + seconds;
        },
        enumerable: true,
        configurable: true
    });
    return BeatmapInfoComponent;
}());
export { BeatmapInfoComponent };
export function getBeatmapHrefString(mode) {
    if (!mode) {
        return 'osu';
    }
    switch (mode) {
        case '0':
            return 'osu';
        case '1':
            return 'taiko';
        case '2':
            return 'fruits';
        case '3':
            return 'mania';
    }
}
