import * as tslib_1 from "tslib";
import { GameService } from './../../../game.service';
import { BehaviorSubject } from 'rxjs';
import { OnInit } from '@angular/core';
var UserListComponent = /** @class */ (function () {
    function UserListComponent(gameService) {
        this.gameService = gameService;
        this.ignoreClickEvent = false;
        this.kickPlayer = this.doKickPlayer.bind(this);
    }
    UserListComponent.prototype.ngOnInit = function () { };
    UserListComponent.prototype.showPlayerStats = function (event, player) {
        this.popupData = { player: player, offsetTop: event.pageY };
        this.ignoreClickEvent = true;
    };
    UserListComponent.prototype.onDocumentClick = function () {
        if (!this.ignoreClickEvent) {
            this.popupData = undefined;
        }
        this.ignoreClickEvent = false;
    };
    UserListComponent.prototype.doKickPlayer = function (player) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.gameService.kickUser(this.game._id, player.osuUserId)];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    Object.defineProperty(UserListComponent.prototype, "alivePlayers", {
        get: function () {
            return this.players.filter(function (p) { return p.alive; });
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(UserListComponent.prototype, "sortedPlayers", {
        get: function () {
            var _this = this;
            var filtered = this.players
                .sort(function (a, b) {
                if (a.gameRank && b.gameRank) {
                    return a.gameRank - b.gameRank;
                }
                if (a.gameRank) {
                    return 1;
                }
                if (b.gameRank) {
                    return -1;
                }
                return a.ppRank - b.ppRank;
            })
                .sort(function (a, b) {
                if (a.username === _this.currentUsername) {
                    return -1;
                }
                else if (b.username === _this.currentUsername) {
                    return 1;
                }
                else {
                    return 0;
                }
            })
                .slice(0, 200);
            if (filtered.length < this.players.length) {
                this.hiddenPlayers = this.players.length - filtered.length;
            }
            return filtered;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(UserListComponent.prototype, "currentUsername", {
        get: function () {
            return this.currentUser && this.currentUser.username;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(UserListComponent.prototype, "isModerator", {
        get: function () {
            return (this.currentUser &&
                this.currentUser.roles &&
                this.currentUser.roles.includes('moderator'));
        },
        enumerable: true,
        configurable: true
    });
    return UserListComponent;
}());
export { UserListComponent };
