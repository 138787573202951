import * as tslib_1 from "tslib";
import { ApiService } from 'src/app/services/api.service';
import * as i0 from "@angular/core";
import * as i1 from "../services/api.service";
var UserProfileResolver = /** @class */ (function () {
    function UserProfileResolver(apiService) {
        this.apiService = apiService;
    }
    UserProfileResolver.prototype.resolve = function (route) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var username, _a;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        username = route.params.username;
                        _a = {};
                        return [4 /*yield*/, this.apiService.get("user/" + username)];
                    case 1: return [2 /*return*/, (_a.user = _b.sent(),
                            _a)];
                }
            });
        });
    };
    UserProfileResolver.ngInjectableDef = i0.defineInjectable({ factory: function UserProfileResolver_Factory() { return new UserProfileResolver(i0.inject(i1.ApiService)); }, token: UserProfileResolver, providedIn: "root" });
    return UserProfileResolver;
}());
export { UserProfileResolver };
