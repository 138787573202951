<div class="ui inverted very padded segment hero">
  <div class="ui container">
    <h1 class="ui inverted header">
      <img src="/assets/favicon.png" class="ui image">
      osuStuff Play
    </h1>

    <h3>
      A new fun way to play casual osu! matches and tournaments.
    </h3>
    <p>
      If you're bored of grinding single player or want a new
      challenge, this is the place
      for you!
      Play through automatically generated matches here at any time and
      measure your skill up against other players!
    </p>


    <div class="center aligned">
      <a class="ui large primary button" [routerLink]="['/lobbies']">
        <i class="play icon"></i>
        Play now!
      </a>
    </div>
  </div>

</div>

<div class="ui container middle-section">
  <div class="ui center aligned three column grid">

    <div class="column">
      <h2 class="ui medium inverted icon header">
        <i class="green users icon"></i>
        <div class="content">
          Play together
          <div class="sub header">
            Up to 1000 players per match
          </div>
        </div>
      </h2>
    </div>

    <div class="column">
      <h2 class="ui medium inverted icon header">
        <i class="orange stopwatch icon"></i>
        <div class="content">
          Real-Time Tournaments
          <div class="sub header">
            Automatic score detection
          </div>
        </div>
      </h2>
    </div>

    <div class="column">
      <h2 class="ui medium inverted icon header">
        <i class="teal crosshairs icon"></i>
        <div class="content">
          Measure your skill
          <div class="sub header">
            Skill-based rating algorithm
          </div>
        </div>
      </h2>
    </div>

    <div class="column">
      <h2 class="ui medium inverted icon header">
        <i class="yellow trophy icon"></i>
        <div class="content">
          New Challenges
          <div class="sub header">
            Lots of fun achievements to unlock
          </div>
        </div>
      </h2>
    </div>

    <div class="column">
      <h2 class="ui medium inverted icon header">
        <i class="purple user icon"></i>
        <div class="content">
          No Signup Required
          <div class="sub header">
            Just login via osu! and play!
          </div>
        </div>
      </h2>
    </div>

    <div class="column">
      <h2 class="ui medium inverted icon header">
        <i class="blue fast hourglass start icon"></i>
        <div class="content">
          Short Tournaments
          <div class="sub header">
            Up to ~30 minutes per match
          </div>
        </div>
      </h2>
    </div>

  </div>

</div>
