<div class="ui inverted form">

  <div class="field">
    <h2>Lobby Details</h2>
    <p>Select the game mode:</p>
    <sui-select class="selection" (selectedOptionChange)="onGameModeChange($event)" [ngModel]="formData.gameMode"
      [options]="gameModes" labelField="label" #modeSelect>
      <sui-select-option *ngFor="let o of modeSelect.filteredOptions" [value]="o">
      </sui-select-option>
    </sui-select>
    <p style="margin-top: 20px;">
      Enter a name for your lobby. This could be your
      tournament title, or some other description
      of the match.
    </p>
    <div class="field">
      <input [(ngModel)]="formData.title" maxlength="70" placeholder="Title of the Lobby">
    </div>

    <div class="field">
      <textarea rows="5" [(ngModel)]="formData.description" maxlength="1500"
        placeholder="A description of your lobby (optional)">

        </textarea>
    </div>
  </div>

  <div class="ui divider"></div>
  <h2>Password protect lobby</h2>
  <div class="field">
    <div class="ui left icon input">
      <input [(ngModel)]="formData.password" maxlength="70" placeholder="Enter a password (optional)">
      <i class="lock icon"></i>
    </div>
  </div>

  <div class="ui divider"></div>
  <h2>Start Date</h2>
  <p>
    Set what time you want the lobby to be open to join. This
    is NOT the time the first round will start.
  </p>
  <p *ngIf="!isEditing">
    Your timezone offset has been detected as UTC{{
    formData.timezoneOffset >= 0 ? '+' : ''}}{{
    formData.timezoneOffset }}.
    You can change the timezone of your lobby's start date if
    required.
  </p>
  <p *ngIf="isEditing">
    You cannot change the timezone offset when editing. Please enter the date in your local time.
  </p>
  <div class="fields">
    <div class="six wide field">
      <label>Lobby open Date</label>
      <div class="ui left icon input">
        <i class="calendar icon"></i>
        <input suiDatepicker [(ngModel)]="formData.startDate" [pickerMinDate]="pickerMinDate"
          placeholder="Scheduled Date">
      </div>
    </div>
    <div class="three wide field">
      <label>Time Zone (UTC+)</label>
      <input [disabled]="isEditing" style="width: 65px;" type="number" [(ngModel)]="formData.timezoneOffset">
    </div>
  </div>

  <div class="ui divider"></div>
  <h2>Players Limit</h2>
  <p>Custom player limits are not supported.</p>
  <!-- <p>
    Select the minimum players required to start the countdown
    for your lobby.
    If the min. number of players isn't reached within 1 hour
    of the start date, the lobby will be closed.
    These values can be changed in the lobby later.
  </p> -->
  <div class="two fields">
    <div class="four wide field">
      <label for="">Minimum # of players (from 4-100)</label>
      <input disabled=true type="number" min="4" max="100" [(ngModel)]="formData.minPlayers">
    </div>
    <div class="four wide field">
      <label for="">Maximum players (from 5-500)</label>
      <input disabled=true type="number" min="4" max="500" [(ngModel)]="formData.maxPlayers">
    </div>
  </div>

  <div class="ui divider"></div>
  <h2>Players rank limit</h2>
  <p>Apply a rank range. Note: Minimum rank = anyone with a
    lower (<) rank number than this cannot join. Maximum rank=anyone with a higher (>) rank number than this cannot
      join.
  </p>

  <div class="two fields">
    <div class="six wide field">
      <sui-checkbox class="toggle" [(ngModel)]="formData.setMinRank">Set
        a minimum rank</sui-checkbox>
    </div>
    <div class="six wide field">
      <sui-checkbox class="toggle" [(ngModel)]="formData.setMaxRank">Set
        a maximum rank</sui-checkbox>
    </div>
  </div>
  <div class="two fields" *ngIf="formData.setMinRank || formData.setMaxRank">
    <div class="six wide field">
      <label for="">Minimum Rank (lowest number that can join)</label>
      <input type="number" min="50" max="1000000" [(ngModel)]="formData.minRank" [disabled]="!formData.setMinRank">
    </div>
    <div class="six wide field">
      <label for="">Maximum Rank (highest number that can
        join)
      </label>
      <input type="number" min="50" max="1000000" [(ngModel)]="formData.maxRank" [disabled]="!formData.setMaxRank">
    </div>
  </div>

  <div class="ui divider"></div>

  <h2>Beatmap selection</h2>

  <p>
    Specify beatmap IDs for each round (optional). If not
    filled in, the game will pick
    beatmaps randomly.
  </p>

  <table class="ui unstackable inverted table">
    <thead>
      <th class="collapsing center aligned">Round #</th>
      <th class="collapsing">Beatmap ID</th>
      <th>Selected Beatmap</th>
      <th>Use Random</th>
    </thead>
    <tbody>
      <tr *ngFor="let roundBeatmap of formData.roundBeatmaps; let index = index;">
        <td class="center aligned">
          {{ index + 1}}
        </td>
        <td>
          <input type="number" class="ui input" (ngModelChange)="getBeatmap($event, index)"
            [(ngModel)]="formData.roundBeatmaps[index].beatmapId" placeholder="e.g. 2485926">
        </td>
        <td>
          <p *ngIf="!roundBeatmap.beatmap && !roundBeatmap.fetching">
            None (A random beatmap will be picked)
          </p>
          <div class="ui active inline loader" *ngIf="roundBeatmap.fetching"></div>
          <p *ngIf="!roundBeatmap.fetching && roundBeatmap.beatmap">
            {{ getBeatmapString(roundBeatmap.beatmap) }}
          </p>
          <p class="error" *ngIf="!roundBeatmap.fetching && roundBeatmap.error">
            {{ roundBeatmap.error }}
          </p>
        </td>
        <td>
          <div class="ui tiny red button" (click)="clearBeatmap(index)">
            X
          </div>
        </td>
      </tr>
    </tbody>
  </table>

  <div class="ui divider"></div>

  <div class="ui inverted red segment" *ngIf="error">
    <h3>An error occured</h3>
    <p>{{ error }}</p>
  </div>

  <button [disabled]="creating" [class.loading]="creating" class="ui green button" (click)="onSubmit(formData)">
    {{ isEditing ? 'Save Lobby': 'Create Lobby' }}
  </button>
</div>
