import * as tslib_1 from "tslib";
import { WebsocketService } from './../services/websocket.service';
import { Observable, interval, Subscription, BehaviorSubject, } from 'rxjs';
import { GameService } from '../game.service';
import { SettingsService } from '../services/settings.service';
import * as Visibility from 'visibilityjs';
import { filter, throttleTime } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "../game.service";
import * as i2 from "../services/settings.service";
import * as i3 from "../services/websocket.service";
var GameLobbyResolver = /** @class */ (function () {
    function GameLobbyResolver(gameService, settingsService, socketService) {
        this.gameService = gameService;
        this.settingsService = settingsService;
        this.socketService = socketService;
        this._game = new BehaviorSubject(undefined);
        this.statusChanged = new BehaviorSubject(undefined);
        this.visibilityTimers = [];
        this.timeLeft = new BehaviorSubject(undefined);
        this.beatmaps = new BehaviorSubject([]);
    }
    GameLobbyResolver.prototype.resolve = function (route) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var id, messages, lobby, players, e_1;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        id = route.params.id;
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 6, , 7]);
                        return [4 /*yield*/, this.socketService.connect(id)];
                    case 2:
                        _a.sent();
                        return [4 /*yield*/, this.getBeatmaps(id)];
                    case 3:
                        _a.sent();
                        return [4 /*yield*/, this.gameService.getLobbyMessages(id)];
                    case 4:
                        messages = _a.sent();
                        lobby = this.getLobby(id);
                        players = this.getPlayers(id);
                        return [4 /*yield*/, this.settingsService.checkCurrentGame()];
                    case 5:
                        _a.sent();
                        return [2 /*return*/, {
                                lobby: lobby,
                                beatmaps: this.beatmaps,
                                players: players,
                                messages: messages,
                                timeLeft: this.timeLeft,
                            }];
                    case 6:
                        e_1 = _a.sent();
                        console.error(e_1);
                        return [2 /*return*/, undefined];
                    case 7: return [2 /*return*/];
                }
            });
        });
    };
    GameLobbyResolver.prototype.getBeatmaps = function (gameId) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _a, _b;
            return tslib_1.__generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        _b = (_a = this.beatmaps).next;
                        return [4 /*yield*/, this.gameService.getLobbyBeatmaps(gameId)];
                    case 1:
                        _b.apply(_a, [_c.sent()]);
                        return [2 /*return*/];
                }
            });
        });
    };
    GameLobbyResolver.prototype.getPlayers = function (gameId) {
        var _this = this;
        return Observable.create(function (observer) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            var subscriptions, updatePlayers;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                subscriptions = new Subscription();
                updatePlayers = function (players, id) {
                    if (observer.closed) {
                        subscriptions.unsubscribe();
                        observer.complete();
                        return;
                    }
                    observer.next(players);
                };
                subscriptions.add(this.socketService.players
                    .pipe(filter(function (val) { return !!val && _this._game.getValue() && val.gameId === _this._game.getValue()._id; }), throttleTime(1000))
                    .subscribe(function (_a) {
                    var players = _a.players, id = _a.gameId;
                    updatePlayers(players, id);
                }));
                return [2 /*return*/];
            });
        }); });
    };
    GameLobbyResolver.prototype.getLobby = function (id) {
        var _this = this;
        return Observable.create(function (observer) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            var subscriptions, onData;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                subscriptions = new Subscription();
                onData = function (game) {
                    if (observer.closed) {
                        subscriptions.unsubscribe();
                        observer.complete();
                        _this._game.next(undefined);
                        _this.statusChanged.next(undefined);
                        return;
                    }
                    observer.next(game);
                    var statusChanged = !_this._game.getValue() ||
                        game.status !== _this._game.getValue().status;
                    _this._game.next(game);
                    if (game.status === 'new' ||
                        statusChanged ||
                        Math.abs(game.secondsToNextRound - _this.secondsLeft) > 10) {
                        _this.secondsLeft = game.secondsToNextRound;
                        if (_this.timeLeftInterval) {
                            _this.timeLeftInterval.unsubscribe();
                        }
                        _this.updateTimeLeft();
                        _this.timeLeftInterval = interval(1000).subscribe(function () {
                            if (_this.secondsLeft >= 1) {
                                _this.secondsLeft--;
                                _this.updateTimeLeft();
                            }
                        });
                    }
                };
                subscriptions.add(this.socketService.lobby.pipe(filter(function (val) { return val && val._id === id; })).subscribe(function (game) {
                    if (game) {
                        onData(game);
                    }
                }));
                return [2 /*return*/];
            });
        }); });
    };
    GameLobbyResolver.prototype.getTimer = function (timeVisible, timeHidden) {
        var _this = this;
        return Observable.create(function (observer) {
            var visibleCbSet = false;
            var timeout;
            var tick = function () {
                var hidden = Visibility.hidden();
                var time = hidden ? timeHidden : timeVisible;
                if (hidden && !visibleCbSet) {
                    visibleCbSet = true;
                    Visibility.onVisible(function () { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                        return tslib_1.__generator(this, function (_a) {
                            clearTimeout(timeout);
                            observer.next();
                            tick();
                            visibleCbSet = false;
                            return [2 /*return*/];
                        });
                    }); });
                }
                timeout = setTimeout(function () { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                    return tslib_1.__generator(this, function (_a) {
                        observer.next();
                        tick();
                        return [2 /*return*/];
                    });
                }); }, time);
            };
            tick();
        });
    };
    GameLobbyResolver.prototype.updateTimeLeft = function () {
        if (!this.secondsLeft) {
            this.timeLeft.next(undefined);
            return;
        }
        if (this.secondsLeft < 0) {
            this.timeLeft.next('now');
            return;
        }
        var date = new Date();
        date.setSeconds(date.getSeconds() + this.secondsLeft);
        var _a = getTimeComponents(date.getTime() - Date.now()), days = _a.days, hours = _a.hours, seconds = _a.seconds, minutes = _a.minutes;
        this.timeLeft.next("" + (parseInt(days, 10) > 0 ? days + "d " : '') + (hours ? hours + ":" : '') + minutes + ":" + seconds);
    };
    GameLobbyResolver.ngInjectableDef = i0.defineInjectable({ factory: function GameLobbyResolver_Factory() { return new GameLobbyResolver(i0.inject(i1.GameService), i0.inject(i2.SettingsService), i0.inject(i3.WebsocketService)); }, token: GameLobbyResolver, providedIn: "root" });
    return GameLobbyResolver;
}());
export { GameLobbyResolver };
export function getTimeComponents(t) {
    var seconds = Math.floor((t / 1000) % 60);
    var minutes = Math.max(0, Math.floor((t / 1000 / 60) % 60));
    var hours = Math.max(0, Math.floor((t / (1000 * 60 * 60)) % 24));
    var days = Math.max(0, Math.floor(t / (1000 * 60 * 60 * 24)));
    return {
        total: t,
        days: days.toString(),
        hours: hours.toString().padStart(2, '0'),
        minutes: minutes.toString().padStart(2, '0'),
        seconds: seconds.toString().padStart(2, '0'),
    };
}
