<div class="ui inverted compact segment no-bg" (document:click)="onDocumentClick()">
  <h4>Players ({{ alivePlayers.length }}/{{ players.length }})</h4>
  <p *ngIf="!players.length">
    No players have joined yet.
  </p>
</div>
<div class="ui relaxed inverted list" *ngIf="players.length">
  <a class="item user" *ngFor="let player of sortedPlayers"
    [ngClass]="player.alive ? 'alive' : 'dead'" [class.winner]="winningUser === player.username"
    (click)="showPlayerStats($event, player)">
    <div class="image-container">
      <img class="ui mini image" [useSrcset]="true" [lazyLoad]="'//a.ppy.sh/' + player.osuUserId"
        alt="">
    </div>
    <div class="content">
      <div class="header">
        <i class="fitted flag" [ngClass]="player.country.toLowerCase()"></i>
        <span [class.my-name]="currentUsername === player.username">{{
          player.username }}
        </span>

        <a class="ui mini blue label stream" target="_blank"
          [href]="'https://www.twitch.tv/' + player.twitch.loginName"
          *ngIf="player.isStreaming">
          <i class="fitted red circle icon"></i>
          Live
        </a>
      </div>
      <div class="description">
        <div class="player info">
          {{ player.ppRank ? ('#' + (player.ppRank | number)) :
          '—' }}
          <span *ngIf="winningUser === player.username" class="text-muted">Winner</span>
          <span *ngIf="player.kicked" class="text-muted">
            Player Kicked
          </span>
          <span *ngIf="player.gameRank && winningUser !== player.username"
            class="text-muted">
            Finished {{ player.gameRank | ordinal }}
          </span>
        </div>
      </div>
    </div>
  </a>
  <div class="item" *ngIf="hiddenPlayers">
    <div class="content">
      <p class="white">+{{ hiddenPlayers}} more players...</p>
    </div>
  </div>
</div>
<app-user-popup *ngIf="popupData" [kickPlayer]="kickPlayer"
  [isModerator]="isModerator" [popupData]="popupData"></app-user-popup>
