import { OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
var LoginComponent = /** @class */ (function () {
    function LoginComponent() {
    }
    LoginComponent.prototype.ngOnInit = function () {
    };
    Object.defineProperty(LoginComponent.prototype, "loginLink", {
        get: function () {
            var id = environment.osu_oauth_id;
            var redirect = environment.osu_redirect_url;
            return ("https://osu.ppy.sh/oauth/authorize?response_type=code&client_id=" + id + "&redirect_uri=" + redirect);
        },
        enumerable: true,
        configurable: true
    });
    return LoginComponent;
}());
export { LoginComponent };
