/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./edit-lobby.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../lobby-form/lobby-form.component.ngfactory";
import * as i3 from "../lobby-form/lobby-form.component";
import * as i4 from "../../services/api.service";
import * as i5 from "@angular/common";
import * as i6 from "./edit-lobby.component";
import * as i7 from "@angular/router";
import * as i8 from "../../game.service";
var styles_EditLobbyComponent = [i0.styles];
var RenderType_EditLobbyComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_EditLobbyComponent, data: {} });
export { RenderType_EditLobbyComponent as RenderType_EditLobbyComponent };
function View_EditLobbyComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "div", [["class", "ui active loader"]], null, null, null, null, null))], null, null); }
function View_EditLobbyComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-lobby-form", [], null, null, null, i2.View_LobbyFormComponent_0, i2.RenderType_LobbyFormComponent)), i1.ɵdid(1, 114688, null, 0, i3.LobbyFormComponent, [i4.ApiService], { formData: [0, "formData"], onSubmit: [1, "onSubmit"], creating: [2, "creating"], error: [3, "error"], isEditing: [4, "isEditing"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.formData; var currVal_1 = _co.editGame; var currVal_2 = (_co.creating || _co.deleting); var currVal_3 = _co.error; var currVal_4 = true; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4); }, null); }
export function View_EditLobbyComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 10, "div", [["class", "ui inverted container section"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 4, "div", [["style", "overflow: hidden;"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "h1", [["class", "ui left floated inverted header"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Edit Lobby"])), (_l()(), i1.ɵeld(4, 0, null, null, 1, "div", [["class", "ui right floated red button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.deleteGame() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Delete Lobby"])), (_l()(), i1.ɵeld(6, 0, null, null, 0, "div", [["class", "ui divider"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_EditLobbyComponent_1)), i1.ɵdid(8, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_EditLobbyComponent_2)), i1.ɵdid(10, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.game; _ck(_v, 8, 0, currVal_0); var currVal_1 = _co.game; _ck(_v, 10, 0, currVal_1); }, null); }
export function View_EditLobbyComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-edit-lobby", [], null, null, null, View_EditLobbyComponent_0, RenderType_EditLobbyComponent)), i1.ɵdid(1, 114688, null, 0, i6.EditLobbyComponent, [i7.ActivatedRoute, i7.Router, i8.GameService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var EditLobbyComponentNgFactory = i1.ɵccf("app-edit-lobby", i6.EditLobbyComponent, View_EditLobbyComponent_Host_0, {}, {}, []);
export { EditLobbyComponentNgFactory as EditLobbyComponentNgFactory };
