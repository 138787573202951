import * as tslib_1 from "tslib";
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from './../../../services/api.service';
import { OnInit, OnDestroy } from '@angular/core';
import { SettingsService } from 'src/app/services/settings.service';
import { environment } from 'src/environments/environment';
var JoinGameComponent = /** @class */ (function () {
    function JoinGameComponent(apiService, settingsService, route, router) {
        var _this = this;
        this.apiService = apiService;
        this.settingsService = settingsService;
        this.route = route;
        this.router = router;
        this.requestingJoin = false;
        this.success = false;
        this.password = '';
        this.loggedIn = !!settingsService.user.getValue();
        settingsService.user.subscribe(function (val) { return (_this.isBanned = val && val.banned); });
    }
    JoinGameComponent.prototype.ngOnInit = function () {
        if (this.route.snapshot.queryParams.autoJoin) {
            this.joinGame();
        }
    };
    JoinGameComponent.prototype.ngOnDestroy = function () {
        this.requestingJoin = false;
        this.success = undefined;
    };
    Object.defineProperty(JoinGameComponent.prototype, "canJoin", {
        get: function () {
            return this.game.status === 'new';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(JoinGameComponent.prototype, "loginLink", {
        get: function () {
            var id = environment.osu_oauth_id;
            var redirect = environment.osu_redirect_url;
            return ("https://osu.ppy.sh/oauth/authorize?response_type=code&client_id=" + id + "&redirect_uri=" + redirect + "&state=" +
                JSON.stringify({ gameId: this.game._id }));
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(JoinGameComponent.prototype, "noPasswordEntered", {
        get: function () {
            return this.game.hasPassword ? !this.password : false;
        },
        enumerable: true,
        configurable: true
    });
    JoinGameComponent.prototype.joinGame = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var e_1;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.requestingJoin = true;
                        this.success = false;
                        this.error = undefined;
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, this.apiService.post("lobbies/" + this.game._id + "/join", { password: this.password })];
                    case 2:
                        _a.sent();
                        this.success = true;
                        this.settingsService.setCurrentGame(this.game._id);
                        responsiveVoice.speak('You have joined the game');
                        return [3 /*break*/, 4];
                    case 3:
                        e_1 = _a.sent();
                        if (e_1.status === 404) {
                            this.error =
                                'A user was not found with the username you entered. Please ensure it is spelled correctly.';
                        }
                        else if (e_1.status === 400) {
                            this.error = 'This game cannot be joined anymore.';
                        }
                        else if (e_1.status === 423) {
                            this.error = 'The game is now full and cannot be joined.';
                        }
                        else if (e_1.status === 401) {
                            if (e_1.error && e_1.error.error) {
                                this.error = e_1.error.error;
                            }
                            else {
                                this.router.navigateByUrl('/login');
                            }
                        }
                        else {
                            throw e_1;
                        }
                        this.requestingJoin = false;
                        return [3 /*break*/, 4];
                    case 4:
                        setTimeout(function () {
                            _this.requestingJoin = false;
                        }, 1000);
                        return [2 /*return*/];
                }
            });
        });
    };
    JoinGameComponent.prototype.leaveGame = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var e_2;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.settingsService.leaveGame(this.game._id)];
                    case 1:
                        _a.sent();
                        return [3 /*break*/, 3];
                    case 2:
                        e_2 = _a.sent();
                        console.error(e_2);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    return JoinGameComponent;
}());
export { JoinGameComponent };
