import * as tslib_1 from "tslib";
import { ApiService } from 'src/app/services/api.service';
import * as i0 from "@angular/core";
import * as i1 from "../services/api.service";
var LeaderboardResolver = /** @class */ (function () {
    function LeaderboardResolver(apiService) {
        this.apiService = apiService;
    }
    LeaderboardResolver.prototype.resolve = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _a;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = {};
                        return [4 /*yield*/, this.apiService.get('users')];
                    case 1: return [2 /*return*/, (_a.users = _b.sent(),
                            _a)];
                }
            });
        });
    };
    LeaderboardResolver.ngInjectableDef = i0.defineInjectable({ factory: function LeaderboardResolver_Factory() { return new LeaderboardResolver(i0.inject(i1.ApiService)); }, token: LeaderboardResolver, providedIn: "root" });
    return LeaderboardResolver;
}());
export { LeaderboardResolver };
