<table class="ui inverted table unstackable">
  <thead>
    <th>Place</th>
    <th>Username</th>
    <th>Score</th>
    <th>Rank</th>
    <th>Accuracy</th>
    <th>Max Combo</th>
    <th>Misses</th>
    <th>Mods</th>
  </thead>
  <tbody>
    <tr [ngClass]="scoreClass(score)" *ngFor="let score of scores; let i = index;">
      <td>{{ score.place || '...' }} {{ score.place && scores[i - 1] && scores[i - 1].place === score.place ? '= ' : '' }}</td>
      <td [class.my-name]="currentUser === score.username">{{ score.username }}</td>
      <td>{{ score.score | number }}</td>
      <td>{{ getRank(score.rank) }}</td>
      <td>{{ score.accuracy }}%</td>
      <td>{{ score.maxCombo }}</td>
      <td>{{ score.misses }}</td>
      <td>{{ getModString(score.mods) || '-' }}</td>
    </tr>
  </tbody>
</table>
