<app-page-loader></app-page-loader>
<div class="ui inverted menu" *ngIf="showMenu">
  <a [routerLink]="'/'" class="item">
    <img src="/assets/osustuff-colors.png" alt="">
    <b>osuStuff</b>
  </a>
  <a class="item" [class.active]="url === '/lobbies'" [routerLink]="'/lobbies'">
    <i class="play icon"></i>
    Play
  </a>
  <a class="item" [class.active]="url === '/leaderboard'" [routerLink]="'/leaderboard'">
    <i class="trophy icon"></i>
    Leaderboard
  </a>
  <a class="item" [class.active]="url === '/achievements'" [routerLink]="'/achievements'">
    <i class="star icon"></i>
    Achievements
  </a>
  <a class="item" [routerLink]="['/faq']">
    <i class="info icon"></i>
    FAQ
  </a>

  <div class="right menu">
    <div class="item" *ngIf="connectionLost" suiPopup popupInverted="true" popupText="The server may be restarting or down for maintenance.">
      <i class="red notched circle loading icon"></i>
      Connection Lost...
    </div>
    <a class="item" [routerLink]="'/login'" *ngIf="!currentUsername">
      <i class="sign-in icon"></i>
      Log In
    </a>
    <a *ngIf="currentGame" class="item" [routerLink]="['/lobbies/', currentGame.gameId]">
      <i class="large orange play circle outline icon"></i>
      Continue Game
    </a>
    <a *ngIf="currentUsername" class="item" id="login" [routerLink]="['/user/', currentUsername]">
      <img class="ui icon" [useSrcset]="true" [lazyLoad]="'//a.ppy.sh/' + currentUser.osuUserId"
        alt="">
      {{ currentUsername }}
    </a>
    <a class="icon link item" href="https://discord.gg/wFxEaE9" target="_blank">
      <i class="large discord icon"></i>
    </a>
    <a class="icon link item" href="https://github.com/Hughp135/osustuff-tournaments" target="_blank">
      <i class="large github icon"></i>
    </a>
  </div>
</div>
<router-outlet></router-outlet>

<app-notifications></app-notifications>

<app-footer *ngIf="showMenu"></app-footer>

<div *ngIf="isAdmin">
  <div style="position: fixed; bottom: 10px; right: 40px; z-index:999;" class="ui mini button" suiPopup
    popupPlacement="top left" [popupTemplate]="popupTemplate" popupTrigger="outsideClick">
    <ng-template let-popup #popupTemplate>
      <div class="content">
        <div class="ui blue button" *ngIf="url === '/lobbies'" (click)="toggleAutoCreation()">Toggle Lobbies AutoCreate</div>
        <div class="ui red button" (click)="clearDb()">Clear DB</div>
        <div class="ui teal button" *ngIf="url.startsWith('/lobbies/')" (click)="toggleFreeze()">Freeze</div>
        <div class="ui orange button" *ngIf="url.startsWith('/lobbies/')" (click)="skipRound()">Skip Round</div>
        <div class="ui red button" *ngIf="url.startsWith('/lobbies/')" (click)="deleteLobby()">Delete Lobby</div>
        <div class="ui green button" (click)="createTestUser()">Create Test User</div>
      </div>
    </ng-template>
    <div>
      <i class="star icon"></i> Admin
    </div>
  </div>
</div>
