<div class="ui inverted basic segment lobbies-container">
  <div class="ui large link inverted divided list">
    <a class="item" *ngFor="let lobby of lobbies" [routerLink]="['/lobbies', lobby._id]">
      <div class="ui stackable grid">
        <div class="seven wide desktop column">
          <i class="left floated large icon" [ngClass]="getLobbyIconClass(lobby)"></i>
          <div class="content">
            <div class="header">
              {{ lobby.title }}
            </div>
            <div class="description">
              {{ gameModeName(lobby.gameMode) }} | {{ getStatus(lobby) }}
            </div>
          </div>
        </div>
        <div class="nine wide desktop column lobby-details">
          <div class="ui equal width right aligned grid">
            <div class="column">
              <h5 *ngIf="lobby.winningUser">
                <i class="yellow fitted trophy icon"></i> {{
                lobby.winningUser.username }}
              </h5>
            </div>
            <div class="column" *ngIf="lobby.roundNumber">
              <div>
                <h5>
                  Round {{ lobby.roundNumber }}
                </h5>
              </div>
            </div>
            <div class="column" *ngIf="lobby.startsAt">
              <h5 *ngIf="lobby.status === 'new'">
                Starting in: {{ lobby.startsAtString }}
              </h5>
              <h5 *ngIf="lobby.status === 'scheduled'">
                Opens in: {{ lobby.startsAtString }}
              </h5>
            </div>
            <div class="column">
              <h5>
                <i class="user icon" [class.green]="lobby.playerCount" [class.grey]="!lobby.playerCount"></i> {{
                lobby.playerCount }}
              </h5>
            </div>
          </div>
        </div>
      </div>

    </a>
  </div>
</div>
