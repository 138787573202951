/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./achievements.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/router";
import * as i3 from "@angular/common";
import * as i4 from "ng2-semantic-ui/dist/modules/dropdown/directives/dropdown-menu";
import * as i5 from "./achievements.component";
import * as i6 from "../../services/api.service";
import * as i7 from "../../services/settings.service";
var styles_AchievementsComponent = [i0.styles];
var RenderType_AchievementsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AchievementsComponent, data: {} });
export { RenderType_AchievementsComponent as RenderType_AchievementsComponent };
function View_AchievementsComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["class", "ui inverted link list"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 4, "a", [["class", "item"], ["fragment", "achievements"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 2).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(2, 671744, null, 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], { fragment: [0, "fragment"], routerLink: [1, "routerLink"] }, null), i1.ɵdid(3, 16384, null, 1, i4.SuiDropdownMenuItem, [i1.Renderer2, i1.ElementRef], null, null), i1.ɵqud(335544320, 1, { childDropdownMenu: 0 }), (_l()(), i1.ɵted(-1, null, ["View your achievements"]))], function (_ck, _v) { var currVal_2 = "achievements"; var currVal_3 = "/user/me"; _ck(_v, 2, 0, currVal_2, currVal_3); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 2).target; var currVal_1 = i1.ɵnov(_v, 2).href; _ck(_v, 1, 0, currVal_0, currVal_1); }); }
function View_AchievementsComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "div", [["class", "column"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 6, "div", [["class", "ui center aligned inverted segment achievement"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "i", [["class", "big icon"]], null, null, null, null, null)), i1.ɵdid(4, 278528, null, 0, i3.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), (_l()(), i1.ɵeld(5, 0, null, null, 1, "h3", [["class", "ui sub header"]], null, null, null, null, null)), (_l()(), i1.ɵted(6, null, [" ", " "])), (_l()(), i1.ɵted(7, null, [" ", " "]))], function (_ck, _v) { var currVal_0 = "big icon"; var currVal_1 = _v.context.$implicit.icon; _ck(_v, 4, 0, currVal_0, currVal_1); }, function (_ck, _v) { var currVal_2 = _v.context.$implicit.title; _ck(_v, 6, 0, currVal_2); var currVal_3 = _v.context.$implicit.description; _ck(_v, 7, 0, currVal_3); }); }
export function View_AchievementsComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "div", [["class", "ui container section"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "h1", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Achievements"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AchievementsComponent_1)), i1.ɵdid(4, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(5, 0, null, null, 2, "div", [["class", "ui four column doubling grid"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AchievementsComponent_2)), i1.ɵdid(7, 278528, null, 0, i3.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.loggedIn; _ck(_v, 4, 0, currVal_0); var currVal_1 = _co.achievements; _ck(_v, 7, 0, currVal_1); }, null); }
export function View_AchievementsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-achievements", [], null, null, null, View_AchievementsComponent_0, RenderType_AchievementsComponent)), i1.ɵdid(1, 114688, null, 0, i5.AchievementsComponent, [i6.ApiService, i7.SettingsService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AchievementsComponentNgFactory = i1.ɵccf("app-achievements", i5.AchievementsComponent, View_AchievementsComponent_Host_0, {}, {}, []);
export { AchievementsComponentNgFactory as AchievementsComponentNgFactory };
