<div class="ui inverted relaxed list" #chatMessages (scroll)="onChatScrolled()" (mousedown)="onMouseDown()"
  (body:mouseup)="onMouseUp()">
  <div class="item" *ngFor="let message of sortedMessages">
    <div class="image-container">
      <img class="ui avatar image" [src]="
          isSystem(message)
            ? '/assets/osustuff-colors.png'
            : '//a.ppy.sh/' + message.osuUserId
        " />
    </div>
    <div class="content">
      <div class="header">
        <span [ngClass]="{ 'system-name': isSystem(message) }">{{
          message.username
          }}</span>
        <span class="text-muted"> {{ message.createdAt | date: 'short' }}</span>
      </div>
      <div class="description chat-message"
        [innerHTML]="message.message | escape | twitchEmotes | emojify | twemojify | userHighlight:currentUsername">
      </div>
    </div>
  </div>
</div>
<form (submit)="sendMessage()">
  <div class="ui fluid action inverted input">
    <input
      #chatInput
      autocomplete="off"
      [disabled]="sendingMessage || !canChat"
      [ngModel]="messageInput | emojify"
      (ngModelChange)="messageInput = $event"
      name="messageInput"
      type="text"
      [placeholder]="canChat ? 'Chat...' : 'You cannot chat'"
    />
    <emoji-mart
      *ngIf="showEmojiPicker"
      (emojiClick)="addEmoji($event)"
    ></emoji-mart>

    <button class="ui inverted icon button show-emoji-picker" (document:click)="windowClick($event)" [disabled]="sendingMessage || !canChat"
      type="button">
      <i class="smile outline icon show-emoji-picker"></i>
    </button>
    <button class="ui inverted icon button" type="submit" [disabled]="sendingMessage || !canChat">
      <i class="comment outline icon"></i>
    </button>
  </div>
</form>
