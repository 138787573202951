import { EditLobbyResolver } from './resolvers/edit-lobby.resolver';
import { UserProfileResolver } from './resolvers/user-profile.resolver';
import { UserProfileComponent } from './components/user-profile/user-profile.component';
import { HomeComponent } from './components/home/home.component';
import { LeaderboardResolver } from './resolvers/leaderboard.resolver';
import { LobbiesResolver } from './resolvers/lobbies-list.resolver';
import { Routes } from '@angular/router';
import { GameLobbyComponent } from './components/game-lobby/game-lobby.component';
import { GameLobbyResolver } from './resolvers/game-lobby.resolver';
import { LeaderboardComponent } from './components/leaderboard/leaderboard.component';
import { LobbiesComponent } from './components/lobbies/lobbies.component';
import { LoginComponent } from './components/login/login.component';
import { FaqComponent } from './components/faq/faq.component';
import { CreateLobbyComponent } from './components/create-lobby/create-lobby.component';
import { EditLobbyComponent } from './components/edit-lobby/edit-lobby.component';
import { AchievementsComponent } from './components/achievements/achievements.component';
var ɵ0 = LobbiesResolver, ɵ1 = EditLobbyResolver, ɵ2 = GameLobbyResolver, ɵ3 = LeaderboardResolver, ɵ4 = UserProfileResolver;
var routes = [
    {
        path: '',
        component: HomeComponent,
    },
    {
        path: 'lobbies',
        component: LobbiesComponent,
        resolve: { data: ɵ0 },
        runGuardsAndResolvers: 'always',
    },
    {
        path: 'lobbies/create',
        component: CreateLobbyComponent,
    },
    {
        path: 'lobbies/:id/edit',
        component: EditLobbyComponent,
        resolve: { data: ɵ1 },
        runGuardsAndResolvers: 'always',
    },
    {
        path: 'lobbies/:id',
        component: GameLobbyComponent,
        resolve: { data: ɵ2 },
        runGuardsAndResolvers: 'always',
    },
    {
        path: 'leaderboard',
        component: LeaderboardComponent,
        resolve: { data: ɵ3 },
        runGuardsAndResolvers: 'always',
    },
    {
        path: 'user/:username',
        component: UserProfileComponent,
        resolve: { data: ɵ4 },
        runGuardsAndResolvers: 'always',
    },
    {
        path: 'login',
        component: LoginComponent,
    },
    {
        path: 'faq',
        component: FaqComponent,
    },
    {
        path: 'achievements',
        component: AchievementsComponent,
    }
];
var AppRoutingModule = /** @class */ (function () {
    function AppRoutingModule() {
    }
    return AppRoutingModule;
}());
export { AppRoutingModule };
export { ɵ0, ɵ1, ɵ2, ɵ3, ɵ4 };
